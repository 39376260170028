import React, { useRef, useState } from 'react';
import "../Styles/HomeStyles.css"
import Layout from '../Components/Layout/Layout';
import { Box, Button, Grid, Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import BuildTogether from '../Components/Layout/BuildTogether';
import Accordian from '../Components/Layout/Accordian';


import { slide_image } from "../Data/HomeSlider_ImgData";

import { Swiper, SwiperSlide } from 'swiper/react';


import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import ClientTrust from '../Components/Layout/ClientTrust';
import Collaboration from '../Components/Layout/Collaboration';
import Methodology from '../Components/Layout/Methodology';
import Testimonial from '../Components/Layout/Testimonial';
import OurServices from '../Components/Layout/OurServices';
import DigitalFuture from '../Components/Layout/DigitalFuture';
import Joinhub from '../Components/Layout/Joinhub';





const Home = () => {
  return (
    <Layout style={{overflowX:'hidden'}}>

      <Grid className='main-cont'
      //  sx={{marginTop:{sm:'0px',xs:'120px'}}}
         style={{overflowX:'hidden'}}>
        <Grid>

          <Typography variant='h3' className='gp_first' sx={{ fontWeight: '600', fontFamily: 'poppins', fontSize: { lg: '3rem', md: '2.8rem', sm: '2.6rem', xs: '1.9rem' } }}>Elevate & Innovate</Typography>
          <Typography variant='h2'  sx={{ color:'#0B0B0B',fontWeight: '600', fontFamily: 'poppins', margin: {sm:'30px 0px',xs:'26px 0px'}, fontSize: { lg: '3.7rem', md: '3.5rem', sm: '3rem', xs: '2rem' } }}>Shaping the Future with Bold Ideas</Typography>
        

        {/* <Grid > */}
          <Typography component={'div'} sx={{ fontSize: { lg: '22px', md: '18px', sm: '14px', xs: '10px' },padding:{sm:'0px 0px',xs:'0px 23px'}, fontWeight: '400', fontFamily: 'poppins', color: "#000",lineHeight:{sm:'1.5',xs:'2.5'} }}>At HyperNxt,We redefine success in the Digital
            realm.As a premier provider of comprehensive</Typography>
          <Typography component={'div'} sx={{ fontSize: { lg: '22px', md: '18px', sm: '14px', xs: '10px' },padding:{sm:'0px 0px',xs:'0px 23px'}, fontWeight: '400', fontFamily: 'poppins', color: "#000" ,lineHeight:{sm:'1.5',xs:'2.5'}}}>software solutions and digital marketing services,we empower business to thrive in the ever-</Typography>
          <Typography component={'div'} sx={{ fontSize: { lg: '22px', md: '18px', sm: '14px', xs: '10px' },padding:{sm:'0px 0px',xs:'0px 23px'}, fontWeight: '400', fontFamily: 'poppins', color: "#000",marginBottom:"20px" ,lineHeight:{sm:'1.5',xs:'2.5'}}}>evolving landscape of the online world.</Typography>
        {/* </Grid> */}
       

   

          <Button sx={{ width: { lg: '300px', md: '250px', sm: '200px', xs: '200px' }, height: { lg: '50px', md: '45px', sm: '40px', xs: '30px' }, color: '#fff', background: '#38B6FF', borderRadius: '30px', fontSize: { lg: '18px', md: '17px', sm: '16px', xs: '10px' }, margin: { lg: '30px 0px', md: "30px 0px", sm: '30px 0px', xs: '15px 0px' }, padding: '0px', fontWeight: '500', fontFamily: 'poppins' }} variant="contained" endIcon={<EastIcon style={{ color: "white" }} />}><a target='_blank' href='https://hyperhub.in'>Join HyperHub</a></Button>
        </Grid>
      </Grid>


      {/* ----------------We help you thrive section---------------------- */}

      <DigitalFuture />

      {/* ------Trust is very Inmportant section-------------- */}
{/*  */}
<Joinhub/>
{/*  */}
      <ClientTrust/>  


      {/* -------collabaretion automate slider------- */}

      <Collaboration />

      {/*--------our services section-------- */}

      <OurServices />

      {/* ---------methodology------- */}

      <Methodology />



      {/* ----------------testimonial--------- */}
      <Testimonial />


      {/* ---------Lets build together------ */}

      <BuildTogether />

      {/* -------Accordian section------- */}

      <Accordian />

    </Layout>
  )
}

export default Home
