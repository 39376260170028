import React from "react";
import Layout from "../Components/Layout/Layout";
import { Box, Typography, Grid } from "@mui/material";
import software_solution_banner from "../Assets/software_solution_banner.png";
import { Link } from "react-router-dom";
import { software_solution_offer } from "../Data/Software_SolutionData";
import BuildTogether from "../Components/Layout/BuildTogether";

const Software_Solutions = () => {
  return (
    <Layout>
      <Box
        sx={{
          width: "100%",
          minHeight: "50vh",
          background: `url(${software_solution_banner}) no-repeat`,
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "700",
            fontFamily: "poppins",
            fontSize: { lg: "60px", md: "50px", sm: "40px", xs: "20px" },
            lineHeight: { lg: "90px", md: "70px", sm: "50px", xs: "30px" },
            fontFamily: "poppins",
          }}
          className="gp_text"
        >
          Software Solutions
        </Typography>

        <Typography
          sx={{
            fontWeight: "500",
            fontFamily: "poppins",
            fontSize: { lg: "25px", md: "20px", sm: "14px", xs: "8px" },
            lineHeight: { lg: "37.5px", md: "25px", sm: "18px", xs: "8px" },
            color: "#fff",
            fontFamily: "poppins",
          }}
        >
          Your Comprehensive Software Solutions Partner
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            "& > .sms_para": {
              margin: {
                lg: "16px 0px",
                md: "14px 0px",
                sm: "12px 0px",
                xs: "9px 0px",
              },
            },
            "& > .sms_para > p": {
              fontWeight: "400",
              fontFamily: "poppins",
              fontSize: {
                xl: "25px",
                lg: "22px",
                md: "16px",
                sm: "12px",
                xs: "9px",
              },
              lineHeight: { lg: "37.5px", md: "25px", sm: "18px", xs: "15px" },
              fontFamily: "poppins",
            },
            width: "90%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box className="sms_para">
            <Typography style={{ textAlign: "justify" }}>
              Welcome to HyperNxt, your one-stop destination for cutting-edge
              software solutions. We offer a diverse range of services tailored
              to meet all your business needs. Whether you require custom
              software development, innovative mobile applications, or
              user-friendly websites, HyperNxt excels in delivering solutions
              that elevate your digital presence.
            </Typography>
          </Box>
          <Box className="sms_para">
            <Typography style={{ textAlign: "justify" }}>
              Our team of experienced developers and designers is dedicated to
              creating bespoke software solutions that align seamlessly with
              your unique requirements. We specialize in streamlining operations
              with advanced ERP systems, enhancing customer engagement through
              intuitive mobile apps, and establishing a robust online presence
              with tailored websites.
            </Typography>
          </Box>
          <Box className="sms_para">
            <Typography style={{ textAlign: "justify" }}>
              At HyperNxt, we are committed to turning your vision into
              reality. With a focus on quality, efficiency, and client
              satisfaction, we take pride in being the go-to partner for
              businesses seeking top-notch software expertise. Experience the
              power of tailored solutions with HyperNxt and transform your
              digital landscape today.
            </Typography>
          </Box>

          <Box className="sms_para">
            <Box>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontFamily: "poppins",
                  margin: "10px 0px",
                  color: "#38B6FF",
                  fontSize: { lg: "30px", md: "25px", sm: "18px", xs: "14px" },
                }}
              >
                Application Development
              </Typography>
            </Box>

            <Typography style={{ textAlign: "justify" }}>
              Application development services refer to the process of creating
              software applications for a variety of platforms, including web,
              mobile, desktop, and embedded systems. These services typically
              involve a range of tasks such as conceptualization, design,
              development, testing, deployment, and maintenance of the
              application.
            </Typography>
          </Box>
          <Box className="sms_para">
            <Box>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontFamily: "poppins",
                  margin: "10px 0px",
                  color: "#38B6FF",
                  fontSize: { lg: "30px", md: "25px", sm: "18px", xs: "14px" },
                }}
              >
                Web Development
              </Typography>
            </Box>

            <Typography style={{ textAlign: "justify" }}>
              In the digital age, a professionally crafted website is your
              gateway to success. Whether you're a small business, a start-up,
              or an established brand, our website development services are
              designed to help you stand out in the crowded online marketplace.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Grid
        container
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {software_solution_offer?.map((item, i) => (
          <Grid
            item
            key={i}
            md={10}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              margin: "20px 0px",
              "& > img": {
                height: { lg: "649px", md: "490px", sm: "350px", xs: "180px" },
                width: { lg: "1104px", md: "790px", sm: "540px", xs: "250px" },
              },
            }}
          >
            <img src={item.Image} alt="item.Image" />
            <button className="buyNow_btn">
              <Link>Buy Now</Link>
            </button>
          </Grid>
        ))}
      </Grid>

      <BuildTogether />
    </Layout>
  );
};

export default Software_Solutions;
