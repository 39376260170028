import digital_marketing_off1 from "../Assets/digital_marketing_off1.png";
import digital_marketing_off2 from "../Assets/digital_marketing_off2.png";
import digital_marketing_off3 from "../Assets/digital_marketing_off3.png";

export const digitalMarketingData = [
  {
    Image: digital_marketing_off1,
  },
  {
    Image: digital_marketing_off2,
  },
  {
    Image: digital_marketing_off3,
  },
];
