import software_solution_off1 from "../Assets/software_solutions_offer1.png";
import software_solution_off2 from "../Assets/software_solutions_offer2.png";
import software_solution_off3 from "../Assets/software_solutions_offer3.png";

export const software_solution_offer = [
  {
    Image:software_solution_off1
  },
  {
    Image:software_solution_off2
  },
  {
    Image:software_solution_off3
  },
]