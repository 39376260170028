import React from 'react';
import {Collaboration_data} from "../../Data/Collaboration_data";
import { Box, Typography } from '@mui/material';
import Marquee from "react-fast-marquee";
import "../../Styles/CollaborationStyles.css"

const Collaboration = () => {
  return (
    <>
       <Box sx={{width:'100%',minHeight:'10vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',margin:{sm:'20px 0px',xs:'10px 0px'}}}>
        <Box sx={{width:'80%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          <Typography variant='h3' sx={{fontWeight:'600',fontFamily:'poppins',fontSize:{lg:'3rem',md:'2.2rem',sm:'1.7rem',xs:'0.75rem'}}} className='gp_text'>We are creating a more equitable world</Typography>
          <Typography variant='h3' sx={{fontWeight:'400',fontFamily:'poppins',fontSize:{lg:'3rem',md:'2.2rem',sm:'1.7rem',xs:'0.75rem'}}}  className='gp_text'>by driving change through digital</Typography>
           <Typography sx={{margin:'20px 0px 0px 0px',fontWeight:'400',fontFamily:'poppins',fontSize:{lg:'20px',md:'14px',sm:'12px',xs:'7px'}}}>Through collaboration with industry pioneers</Typography>
        </Box>
          
          <Box className='logos'>
          <Marquee
          speed={60}
          style={{
            height: "20vh",
            width: "100%",
            backgroundColor: "white",
            marginTop: 30,
            overflow: "hidden",
          }}
          >
          {
           Collaboration_data.map((item)=>(
            <>
            <Box className="logos_slide">
              <img src={item.Image} alt="item.Image" />
            </Box>
            </>
           )) 
          }
          </Marquee>
          </Box>
      </Box>
    </>
  )
}

export default Collaboration;
