import React from 'react'
import Layout from '../Components/Layout/Layout';
import { Box, Typography ,Card, CardContent, CardMedia,Grid,Button } from '@mui/material';
import sms_services_home_banner from "../Assets/sms_service_home_banner.png";
import {SMS_Service_Data} from "../Data/SMS_ServiceData";
import { Build } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import BuildTogether from '../Components/Layout/BuildTogether';


const SMS_Services = () => {
 
  return (
    <Layout>
      <Box sx={{width:'100%',minHeight:'50vh',background:`url(${sms_services_home_banner}) no-repeat`,backgroundPosition:'center',backgroundSize:'100% 100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
        <Typography variant='h3' sx={{fontWeight:'700',fontFamily:'poppins',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'20px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'30px'},fontFamily:'poppins'}} className='gp_text'>SMS Service</Typography>

        <Typography sx={{fontWeight:'500',fontFamily:'poppins',fontSize:{lg:'25px',md:'20px',sm:'14px',xs:'10px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'8px'},color:'#fff',fontFamily:'poppins'}}>Unleash the Power of Hyper Messaging with HyperNxt!</Typography>
      </Box>

      <Box  sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
      <Box sx={{'& > .sms_para':{margin:{lg:'16px 0px',md:'14px 0px',sm:'12px 0px',xs:'9px 0px'}},'& > .sms_para > p':{fontWeight:'400',fontFamily:'poppins',fontSize:{xl:'25px',lg:'22px',md:'16px',sm:'12px',xs:'9px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'15px'},fontFamily:'poppins'},width:'90%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
      <Box className='sms_para'>
          <Typography>Are you ready to elevate your promotional game? Look no further than HyperNxt, your ultimate partner in propelling your brand to new heights through the art of SMS marketing.          
          </Typography>
      
      </Box>
      <Box className='sms_para'>
          <Typography>At HyperNxt, we understand the importance of effective communication in today's fast-paced digital world.That's why we've perfected the science of HyperMessaging – a dynamic approach to promotional SMS that goes beyond the ordinary.        
          </Typography>
          
      </Box>
      <Box className='sms_para'>
        <Typography>
        At HyperNxt, we are committed to turning your vision into reality. With a focus on quality, efficiency, and client satisfaction, we take pride in being the go-to partner for businesses seeking top-notch software expertise. Experience the power of tailored solutions with HyperNxt and transform your digital landscape today.
        </Typography>
      
      </Box>
      <Box className='sms_para'>
        <Typography>
          Are you ready to revolutionize your promotional efforts? Join the HyperRevolution with HyperNxt and experience the difference that HyperMessaging can make for your brand. From boosting brand awareness to driving sales, we've got your SMS marketing needs covered.br Get in touch with us today and let's embark on a journey of hyper-connectivity and unparalleled impact!
        </Typography>
     
      </Box>
      </Box>
      </Box>

      <Grid container sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          
            {
              SMS_Service_Data?.map((item,i)=>(
                <Grid item key={i} sm={3.7} xs={12} sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  margin: '20px 0px',
                }}>
                  <Card sx={{ border: 'none' }}>
                    <CardMedia
                      component="img"
                      height={{ xl: '510px', lg: '380px', md: '280px', sm: '180px', xs: '230px' }}
                      width={{ xl: '430px', lg: '340px', md: '260px', sm: '180px', xs: '230px' }}
                      image={item.Image}
                      alt="Item Image"
                      sx={{ transition: 'transform 0.3s' }}
                      // sx={{
                      //   '&:hover': {
                      //     transform: 'translateY(-5px)',
                      //   }
                      // }}
                    />
                    <CardContent>
                      <Button className='buyNow_btn' component={Link} to="/buy-now" sx={{
                        transition: 'transform 0.3s',
                        '&:hover': {
                          transform: 'translateY(-5px)',
                        }
                      }}>Buy Now</Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            }
          
      </Grid>

      <BuildTogether/>
    </Layout>
  )
}

export default SMS_Services;
