import sms_service_off1 from "../Assets/sms_off_1.png";
import sms_service_off2 from "../Assets/sms_off_2.png";
import sms_service_off3 from "../Assets/sms_off_3.png";
import sms_service_off4 from "../Assets/sms_off_4.png";
import sms_service_off5 from "../Assets/sms_off_5.png";
import sms_service_off6 from "../Assets/sms_off_6.png";

export const SMS_Service_Data = [
  {
    Image:sms_service_off1
  },
  {
    Image:sms_service_off2
  },
  {
    Image:sms_service_off3
  },
  {
    Image:sms_service_off4
  },
  {
    Image:sms_service_off5
  },
  {
    Image:sms_service_off6
  },
]