import React from 'react';
import Layout from '../Components/Layout/Layout';
import termservices_banner from '../Assets/termservices_banner.png';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const TermOfServices = () => {
  return (
    <Layout>
      <Box sx={{width:'100%',minHeight:'50vh',background:`url(${termservices_banner}) no-repeat`,backgroundPosition:'center',backgroundSize:'cover',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
        <Typography variant='h3' sx={{fontWeight:'700',fontFamily:'poppins',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'18px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'30px'},}} className='gp_text'>HyperNxt TECHNOLOGY PVT LTD</Typography>

        <Typography variant='h3' sx={{fontWeight:'700',fontFamily:'poppins',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'18px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'30px'},}} className='gp_text'>Terms and Conditions</Typography>

      </Box>

      <Box  sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
      <Box sx={{'& > .sms_para':{margin:{lg:'16px 0px',md:'14px 0px',sm:'12px 0px',xs:'9px 0px'}},'& > .sms_para > p':{fontWeight:'400',fontFamily:'poppins',fontSize:{xl:'25px',lg:'22px',md:'16px',sm:'12px',xs:'9px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'15px'},fontFamily:'poppins'},width:'90%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column','& > .sms_para > p > span > a':{fontSize:{xl:'25px',lg:'22px',md:'16px',sm:'12px',xs:'9px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'15px'},color:'#38B6FF'}}}>


        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'},fontFamily:'poppins'}}>1. Acceptance of Terms</Typography>
          </Box>

          <Typography>
          By accessing and using the HyperNxt TECHNOLOGY PVT LTD website, you agree to comply with and be bound by these terms and conditions. If you do not agree to these terms, please do not use the website.
          </Typography>
         
        </Box>
        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'},fontFamily:'poppins'}}>2. Changes to Terms</Typography>
          </Box>

          <Typography>
             HyperNxt TECHNOLOGY PVT LTD reserves the right to modify, add, or remove any part of these terms at any time. It is your responsibility to check these terms periodically for changes. Your continued use of the website following the posting of changes constitutes acceptance of those changes.
          </Typography>
        </Box>

        <Box className='sms_para'>
         <Box>
         <Typography sx={{fontWeight:'700',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'},fontFamily:'poppins'}}>3. Intellectual Property</Typography>
         </Box>

          <Typography>         
             All content on this website, including text, graphics, logos, images, and software, is the property of HyperNxt TECHNOLOGY PVT LTD and is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works without explicit permission.
          </Typography>
          
        </Box>

        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'},fontFamily:'poppins'}}>4. User Conduct</Typography>
          </Box>

          <Typography>
           You agree not to engage in any conduct that may disrupt or interfere with the operation of the website, including but not limited to hacking, uploading malicious code, or attempting to gain unauthorized access.
          </Typography>
          
        </Box>

        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'},fontFamily:'poppins'}}>5. Privacy Policy</Typography>
          </Box>

          <Typography>
          Your use of the website is also governed by our Privacy Policy. By using the website, you consent to the terms of the Privacy Policy.
          </Typography>
          
        </Box>

        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'},fontFamily:'poppins'}}>6. Limitation of Liability</Typography>
          </Box>

          <Typography>
          HyperNxt TECHNOLOGY PVT LTD is not liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with your use of the website.
          </Typography>
          
        </Box>

        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'},fontFamily:'poppins'}}>7. Contact Information</Typography>
          </Box>

          <Typography>
          If you have any questions or concerns about these terms and conditions, please <Typography component={'span'}><Link> info@thehypernxt.com</Link></Typography>.
          </Typography>
          
        </Box>
       
      </Box>
      </Box>
    </Layout>
  )
}

export default TermOfServices
