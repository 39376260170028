import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import H_logo from "../../Assets/H_logo.png";
import H_logo_back from "../../Assets/64c10077397f6590fdff5978_Contact Form Pattern 1.png";

const DigitalFuture = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "10vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: {
            xl: "70px 0px",
            lg: "60px 0px",
            md: "60px 0px",
            sm: "40px 0px",
            xs: "40px 0px",
          },
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: {
              xl: "771px",
              lg: "600px",
              md: "500px",
              sm: "380px",
              xs: "auto",
            },
            background: "rgba(56, 182, 255, 0.35)",
            display: "flex",
            alignItems: { sm: "flex-end", xs: "flex-start" },
          }}
        >
          <Grid container>
            <Grid
              item
              sm={3.5}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <Box
                className="namessssssss"
                sx={{
                  width: "100%",
                  height: {
                    xl: "653px",
                    lg: "500px",
                    md: "400px",
                    sm: "470px",
                    xs: "120px",
                  },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  "& > img": {
                    width: {
                      xl: "270px",
                      lg: "220px",
                      md: "150px",
                      sm: "120px",
                      xs: "100px",
                    },
                    height: {
                      xl: "255px",
                      lg: "200px",
                      md: "120px",
                      sm: "90px",
                      xs: "80px",
                    },
                  },
                  zIndex: "2",
                }}
              >
                {/* <img src={H_logo} alt="H_logo" /> */}
              </Box>
            </Grid>
            <Grid
              item
              sm={8}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                paddingLeft: {
                  xl: "50px",
                  lg: "40px",
                  md: "30px",
                  sm: "20px",
                  xs: "0px",
                },
                padding: { xs: "20px 10px" },
              }}
            >
              <Box
                sx={{
                  "& > p": {
                    fontSize: {
                      xl: "56px",
                      lg: "45px",
                      md: "38px",
                      sm: "25px",
                      xs: "16px",
                    },
                    fontWeight: "600",
                    fontFamily: "poppins",
                    lineHeight: {
                      xl: "84px",
                      lg: "70px",
                      md: "49px",
                      sm: "30px",
                      xs: "20px",
                    },
                  },
                }}
              >
                <Typography className="gp_text">
                  We help you thrive in
                </Typography>
                <Typography className="gp_text">
                  the digital present and
                </Typography>
                <Typography className="gp_text">future</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  "& > p": {
                    fontSize: {
                      xl: "22px",
                      lg: "18px",
                      md: "13px",
                      sm: "10px",
                      xs: "9px",
                    },
                    fontWeight: "400",
                    lineHeight: {
                      xl: "33px",
                      lg: "25px",
                      md: "20px",
                      sm: "16px",
                      xs: "15px",
                    },
                    fontWeight: "400",
                    fontFamily: "poppins",
                  },
                }}
              >
                <Typography>
                  Embark on a transformative journey with HyperNxt as we pledge
                  to
                </Typography>
                <Typography>
                  empower businesses and elevate success in the digital realm.{" "}
                </Typography>
                <Typography>
                  Navigating beyond boundaries,our aim is to facilitate your
                  business{" "}
                </Typography>
                <Typography>
                  prosperity through strategic solutions and seamless growth. At{" "}
                </Typography>
                <Typography>
                  HyperNxt, we are committed to unleashing your digital
                  potential,{" "}
                </Typography>
                <Typography>
                  ensuring your triumph in the dynamic landscape of the digital
                  world.
                </Typography>
                <Typography>
                  Join us in elevating your business digitally, as we work
                  hand-in-hand
                </Typography>
                <Typography>
                  to pave the way for your path to prosperity.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default DigitalFuture;
