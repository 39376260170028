import React from "react";
import Layout from "../Components/Layout/Layout";
import { Box, Typography, Grid } from "@mui/material";
import digital_marketing_banner from "../Assets/digital_marketing_banner.png";
import { digitalMarketingData } from "../Data/Digital_MarketingData";
import { Link } from "react-router-dom";
import BuildTogether from "../Components/Layout/BuildTogether";

const Digital_Marketing = () => {
  return (
    <Layout>
      <Box
        sx={{
          width: "100%",
          minHeight: "50vh",
          background: `url(${digital_marketing_banner}) no-repeat`,
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "700",
            fontFamily: "poppins",
            fontSize: { lg: "60px", md: "50px", sm: "40px", xs: "20px" },
            lineHeight: { lg: "90px", md: "70px", sm: "50px", xs: "30px" },
            fontFamily: "poppins",
          }}
          className="gp_text"
        >
          Digital Marketing
        </Typography>

        <Typography
          sx={{
            fontWeight: "500",
            fontFamily: "poppins",
            fontSize: { lg: "25px", md: "20px", sm: "14px", xs: "8px" },
            lineHeight: { lg: "37.5px", md: "25px", sm: "18px", xs: "8px" },
            color: "#fff",
            fontFamily: "poppins",
          }}
        >
          Your Strategic Digital Marketing Ally
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            "& > .sms_para": {
              margin: {
                lg: "16px 0px",
                md: "14px 0px",
                sm: "12px 0px",
                xs: "9px 0px",
              },
            },
            "& > .sms_para > p": {
              fontWeight: "400",
              fontFamily: "poppins",
              fontSize: {
                xl: "25px",
                lg: "22px",
                md: "16px",
                sm: "12px",
                xs: "9px",
              },
              lineHeight: { lg: "37.5px", md: "25px", sm: "18px", xs: "15px" },
              fontFamily: "poppins",
            },
            width: "90%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box className="sms_para">
            <Typography style={{ textAlign: "justify" }}>
              Welcome to HyperNxt, where we extend our expertise beyond
              software solutions to empower your brand through cutting-edge
              digital marketing strategies. In the ever-evolving digital
              landscape, we understand the importance of a strong online
              presence and effective marketing campaigns.
            </Typography>
          </Box>
          <Box className="sms_para">
            <Typography style={{ textAlign: "justify" }}>
              Our digital marketing services are crafted to elevate your brand,
              drive targeted traffic, and maximize your online reach. Here's how
              HyperNxt can be your trusted partner in the realm of digital
              marketing:
            </Typography>
          </Box>
          <Box className="sms_para">
            <Typography style={{ textAlign: "justify" }}>
              At HyperNxt, we are not just a digital marketing agency; we are
              your dedicated partner in achieving online success. Join forces
              with us to navigate the dynamic digital landscape, enhance your
              brand visibility, and achieve measurable results in the
              ever-evolving online marketplace. Experience the HyperNxt
              difference in digital marketing today.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Grid
        container
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {digitalMarketingData?.map((item, i) => (
          <Grid
            item
            key={i}
            sm={3.7}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              margin: "20px 0px",
              "& > img": {
                height: {
                  xl: "510px",
                  lg: "380px",
                  md: "280px",
                  sm: "180px",
                  xs: "230px",
                },
                width: {
                  xl: "430px",
                  lg: "340px",
                  md: "260px",
                  sm: "180px",
                  xs: "230px",
                },
              },
            }}
          >
            <img src={item.Image} alt="item.Image" />
            <button className="buyNow_btn">
              <Link>Buy Now</Link>
            </button>
          </Grid>
        ))}
      </Grid>
      <div style={{ marginTop: "2rem" }}>
        <BuildTogether />
      </div>
    </Layout>
  );
};

export default Digital_Marketing;
