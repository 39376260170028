import { Box, Typography, colors } from '@mui/material'
import React from 'react';
import methodology_vertical_img from "../../Assets/methodology_horz_img.png";

const Methodology = () => {
  return (
    <>
      <Box sx={{width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',margin:{xl:'60px 0px',lg:'70px 0px',md:'30px 0px',sm:'20px 0px',xs:'10px 0px'}}}>
        <Box sx={{'& > p':{fontSize:{xl:'30px',lg:'28px',md:'20px',sm:'13px',xs:'9px'},color:'#0B0B0BB2',fontWeight:'500',fontFamily:"'Poppins', sans-serif",lineHeight:{xl:'45px',lg:'40px',md:'30px',sm:'20px',xs:'10px'}}}}>
          <Typography>HyperNxt Methodology</Typography>
        </Box>
        <Box sx={{'& > p':{fontSize:{xl:'60px',lg:'50px',md:'40px',sm:'25px',xs:'12px'},fontFamily:"'Poppins', sans-serif",fontWeight:'700',lineHeight:{xl:'90px',lg:'70px',md:'50px',sm:'30px',xs:'22px'}},display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          <Typography className='gp_text'>Proven Strategies for Supercharging Your         
          </Typography>
          <Typography sx={{color:'#509AFF'}}>Success</Typography>
          
        </Box>

        <Box sx={{width:'80%',margin:'30px 0px','& > img':{width:'100%',height:{xl:'500px',lg:'400px',md:'400px',sm:'300px'}}}}>
          <img src={methodology_vertical_img} alt="methodology_vertical_img" />
        </Box>
        
        
      </Box>
    </>
  )
}

export default Methodology
