import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import build_together_back from "../../Assets/build_together_back.png";
import { Link } from "react-router-dom";
import "../../Styles/BuildTogetherStyles.css";
import { useState } from "react";
import { useMemo } from "react";

const BuildTogether = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });
  const showPleaseWaitMessage = () => {
    const submitButton = document.getElementById("submitButton");
    submitButton.innerText = "Please wait...";
    submitButton.disabled = true;
  };

  const hidePleaseWaitMessage = () => {
    const submitButton = document.getElementById("submitButton");
    submitButton.innerText = "Submit";
    submitButton.disabled = false;
  };
  const memoizedFormData = useMemo(
    () => ({
      name: formData.name,
      number: formData.number,
      email: formData.email,
      message: formData.message,
    }),
    [formData]
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    showPleaseWaitMessage();

    const formDataToSubmit = new FormData();
    formDataToSubmit.append("name", formData.name);
    formDataToSubmit.append("number", formData.number);
    formDataToSubmit.append("email", formData.email);
    formDataToSubmit.append("message", formData.message);

    fetch(
      "https://script.google.com/macros/s/AKfycbyRNuF8sDfmfFbEWsy6Xmqf8Cv6SDbW7mpaiTcJ43ly37onNNnqtKvwaxvkmQ3x1oe-/exec",
      {
        method: "POST",
        body: formDataToSubmit,
      }
    )
      .then((response) => response.text())
      .then((data) => {
        console.log(data); // Handle response from server if needed
        alert("Form submitted successfully!");
        setFormData({ name: "", number: "", message: "", email: "" });
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while submitting the form.");
      })
      .finally(() => {
        hidePleaseWaitMessage();
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "10vh",
          background: `url(${build_together_back})`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "30px 0px",
        }}
      >
        <Grid className="rightbulid">

{/* Left Grid Item */}
<Grid item sm={5} xs={12}  sx={{ display: 'flex', flexDirection: 'column', alignItems: { sm: 'flex-start', xs: 'center' }, paddingLeft: { sm: '50px', xs: '0px' }, gap: '1rem', justifyContent: 'center', textAlign: 'center' }}>
  <Typography variant='h4' sx={{ fontWeight: "800", fontFamily: 'poppins', fontSize: { lg: '3rem', md: '2.5rem', sm: '1.5rem', xs: '1.5rem' } }}>Let’s Build</Typography>
  <Typography>
    <Typography variant='h4' sx={{ fontWeight: "800", fontFamily: 'poppins', color: 'blue', fontSize: { lg: '3rem', md: '2.5rem', sm: '1.5rem', xs: '1.5rem' } }} component={'span'}>Future</Typography>
    <Typography variant='h4' sx={{ fontWeight: "800", fontFamily: 'poppins', fontSize: { lg: '3rem', md: '2.5rem', sm: '1.5rem', xs: '1.5rem' } }} component={'span'}> Together</Typography>
  </Typography>
  <Typography variant='h6' sx={{ fontSize: { lg: '1.25rem', md: '0.8rem', sm: '0.48rem', xs: '0.48rem' }, fontFamily: 'poppins', fontWeight: '400' }}>Get in Touch: Together, Let's Shape a Prosperous Future.</Typography>
</Grid>

{/* Right Grid Item */}
<Grid item sm={7} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Box className='help_box' sx={{ width: { lg: '450px', md: '350px', sm: '280px', xs: '260px' }, height: { lg: '500px', md: '450px', sm: '400px', xs: '360px' }, borderRadius: '20px', border: '1px solid gray', padding: '10px', display: 'flex', flexDirection: 'column', marginTop: { sm: '0px', xs: '20px' }, alignItems: 'center' }}>
    <Typography sx={{ marginBottom: '20px', fontWeight: '600', fontFamily: 'poppins' }}>Need Help?</Typography>
    <form action="" className='bulid_together_form'>
      <input
        type="text"
        name="name"
        placeholder="Enter Your Name"
        value={memoizedFormData.name} onChange={handleChange}
      />
      <input
        type="email"
        name="email"
        id="LetsBuildPage_Form_InputBox"
        placeholder="Enter Your Email I'D"
        value={memoizedFormData.email} onChange={handleChange}
      />               
      <input
        type="number"
        name="number"
        id="LetsBuildPage_Form_InputBox"
        placeholder="Enter Your Number"
        value={memoizedFormData.number} onChange={handleChange}
      />
      <textarea
        name="message"
        // id="LetsBuildPage_Textarea_form"
        placeholder="Your Message"
        value={memoizedFormData.message} onChange={handleChange}
        style={{color:"#000"}}
      ></textarea>
      {/* <textarea name="" id="" placeholder='Message' ></textarea> */}
      {/* <button style={{ marginTop: '17px' }}>Send Message</button> */}
      <button type="submit" style={{ marginTop: '17px' }} onClick={handleSubmit} id='submitButton'>Submit</button>

    </form>
  </Box>
</Grid>

</Grid>

      </Box>
    </>
  );
};

export default BuildTogether;
