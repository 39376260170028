import React from 'react'
import Layout from '../Components/Layout/Layout'
import { Box, Grid, Typography } from '@mui/material';
import carrer_opertunities_banner from '../Assets/carrer_opertunities_banner.png';
import "../Styles/CarrerOpertunitiesStyles.css"
import { Link } from 'react-router-dom';
import {carrier_data} from "../Data/CarrerOpertunitiesData";
import carrie_jorney_back_banner from "../Assets/carrier_journey_back_banner.png";

const CarrerOpertunities = () => {
  const handleClick = () => {
    window.location.href = "https://forms.gle/ivcZe2qH5ka4t2Gu6";
  };
  return (
    <Layout>
      <Box sx={{width:'100%',minHeight:'50vh'
      ,background:`url(${carrer_opertunities_banner}) no-repeat`
      ,backgroundPosition:'center',backgroundSize:'cover',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
        <Typography variant='h3' sx={{fontWeight:'700',fontFamily:'poppins',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'14px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'25px'},}} className='gp_text'>Explore Exciting Career Opportunities at </Typography>

        <Typography variant='h3' sx={{fontWeight:'700',fontFamily:'poppins',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'14px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'25px'},}} className='gp_text'>HyperNxt </Typography>

        <Typography sx={{fontWeight:'500',fontFamily:'poppins',fontSize:{lg:'25px',md:'20px',sm:'14px',xs:'7px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'12px'},color:'#fff'}}>We're on the lookout for passionate and talented individuals to join our team! At HyperNxt, we</Typography>

        <Typography sx={{fontWeight:'500',fontFamily:'poppins',fontSize:{lg:'25px',md:'20px',sm:'14px',xs:'7px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'8px'},color:'#fff'}}>believe in fostering innovation, collaboration, and continuous growth. Check out our current</Typography>

        <Typography sx={{fontWeight:'500',fontFamily:'poppins',fontSize:{lg:'25px',md:'20px',sm:'14px',xs:'7px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'8px'},color:'#fff'}}>openings:</Typography>
      </Box>

        <Box sx={{padding:'30px 0px'}}>
        {
          carrier_data?.map((item,i)=>(
              <>
          <Grid container sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} key={i}>
         <Box className='carrier_box' sx={{height:{xl:'317px',lg:'317px',md:'275px',sm:'230px',xs:'185px'}}}>
          <Grid item sx={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'flex-start'}}>
          <Typography sx={{fontSize:{xl:'40px',lg:'35px',md:'30px',sm:'25px',xs:'14px'},fontWeight:'700',fontFamily:'poppins',color:'#0B0B0B',lineHeight:{xl:'60px',lg:'55px',md:'48px',sm:'30px',xs:'20px'}}}>{item.header}</Typography>

           <Typography sx={{fontSize:{xl:'25px',lg:'22px',md:'20px',sm:'16px',xs:'10px'},fontWeight:'400',fontFamily:'poppins',color:'#0B0B0B',lineHeight:{xl:'37.5px',lg:'35px',md:'30px',sm:'26px',xs:'15px'}}}>{item.about}</Typography>

           <Box sx={{
           '& > button':{border:'none',borderRadius:'10px',outline:'none',background:'#38B6FF',width:{xl:'300px',lg:'300px',md:'250px',sm:'210px',xs:'122px'},height:{xl:'60px',lg:'60px',md:'46px',sm:'40px',xs:'27px'}, margin:'15px 0px',},
           '& > button > a':{fontSize:{xl:'25px',lg:'23px',md:'20px',sm:'16px',xs:'10px'},fontWeight:'600',fontFamily:'poppins',color:'#fff',textDecoration:'none'}
           }}>
             <button ><Link>Apply Now</Link></button>
           </Box>
           
          </Grid>
         </Box>
        </Grid>
              </>
          ))
        }
        </Box>

        <Box sx={{background:`url(${carrie_jorney_back_banner}) no-repeat`,backgroundSize:'cover',backgroundPosition:'center',width:'100%',height:{lg:'367px',md:'320px',sm:'280px',xs:'112px'},display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'
        ,'& > P':{fontSize:{xl:'40px',lg:'32px',md:'25px',sm:'18px',xs:'8px'},fontWeight:'700',fontFamily:'poppins',color:'#0B0B0B',lineHeight:{xl:'60px',lg:'55px',md:'40px',sm:'26px',xs:'20px'}},
              '& > p > span':{fontSize:{xl:'40px',lg:'32px',md:'25px',sm:'18px',xs:'8px'},fontWeight:'700',fontFamily:'poppins',color:'#38B6FF',lineHeight:{xl:'60px',lg:'55px',md:'40px',sm:'26px',xs:'20px'}}}}>
          <Typography>Ready to take the <Typography component={'span'}>next step</Typography> in your career journey? Apply now to</Typography>
          <Typography>be part of the <Typography component={'span'}>HyperNxt family</Typography>!</Typography>

          <button className='apply_now' onClick={handleClick}><Link>Apply Now</Link></button>
        </Box>
       
    </Layout>
  )
}

export default CarrerOpertunities
