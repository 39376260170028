import bulk_whatsapp_off1 from "../Assets/bulk_whatsapp_off1.png";
import bulk_whatsapp_off2 from "../Assets/bulk_whatsapp_off2.png";
import bulk_whatsapp_off3 from "../Assets/bulk_whatsapp_off3.png";
import bulk_whatsapp_off4 from "../Assets/bulk_whatsapp_off4.png";

import bulk_whatsapp_special_off1 from "../Assets/bulk_whatsapp_special_off1.png";
import bulk_whatsapp_special_off2 from "../Assets/bulk_whatsapp_special_off2.png";
import bulk_whatsapp_special_off3 from "../Assets/bulk_whatsapp_special_off3.png";
import bulk_whatsapp_special_off4 from "../Assets/bulk_whatsapp_special_off4.png";

export const bulkWhatsappOffer =[
  {
    Image:bulk_whatsapp_off1
  },
  {
    Image:bulk_whatsapp_off2
  },
  {
    Image:bulk_whatsapp_off3
  },
  {
    Image:bulk_whatsapp_off4
  },
]

export const bulkWhatsappSpecialOffer = [
  {
    Image:bulk_whatsapp_special_off1
  },
  {
    Image:bulk_whatsapp_special_off2
  },
  {
    Image:bulk_whatsapp_special_off3
  },
  {
    Image:bulk_whatsapp_special_off4
  },
]