import collaboration_1 from "../Assets/collaboration_slidelogo1.png";
import collaboration_2 from "../Assets/collaboration_slidelogo2.png";
import collaboration_3 from "../Assets/collaboration_slidelogo3.png";
import collaboration_4 from "../Assets/collaboration_slidelogo4.png";
import collaboration_5 from "../Assets/collaboration_slidelogo5.png";
import collaboration_6 from "../Assets/collaboration_slidelogo6.png";
import collaboration_7 from "../Assets/collaboration_slidelogo7.png";
import collaboration_8 from "../Assets/collaboration_slidelogo8.png";
import collaboration_9 from "../Assets/collaboration_slidelogo9.png";


export const Collaboration_data = [
  {
    Image:collaboration_1
  },
  {
    Image:collaboration_2
  },
  {
    Image:collaboration_3
  },
  {
    Image:collaboration_4
  },
  {
    Image:collaboration_5
  },
  {
    Image:collaboration_6
  },
  {
    Image:collaboration_7
  },
  {
    Image:collaboration_8
  },
  {
    Image:collaboration_9
  },
  {
    Image:collaboration_5
  },
  {
    Image:collaboration_3
  },
  {
    Image:collaboration_1
  },
  {
    Image:collaboration_7
  },
]