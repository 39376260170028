import React from 'react'
import Layout from '../Components/Layout/Layout';
import { Box, Typography,Grid } from '@mui/material';
import "../Styles/AboutStyles.css";
import H_logo from "../Assets/H_logo.png";
import H_logo_back from "../Assets/H_logo_back.png";
import BuildTogether from '../Components/Layout/BuildTogether';

const AboutUs = () => {
  return (
    <Layout>
      <Box sx={{width:'100%',minHeight:'10vh',display:'flex',justifyContent:'center',alignItems:'center'
      ,paddingBottom:'50px'
      }}>

        <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',width:{md:'80%',sm:'90%',xs:'90%'}}}>
       <Typography variant='h2' className='about_text' sx={{fontWeight:'700',fontFamily:'poppins',fontSize:{lg:'3rem',md:'2.8rem',sm:'2.6rem',xs:'1.9rem'},margin:{sm:'30px 0px',xs:'10px 0px'}}}>About Us</Typography>

       <Box component={'div'} sx={{fontSize:'22px',margin:{sm:'30px 0px',xs:'10px 0px'},color:'black',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',
    '& > p':{fontSize:{lg:'22px',md:'17px',sm:'13px',xs:'6px'}}}}>
        <Typography >At HyperNxt,We redefine success in the Digital
          realm.As a premier provider of comprehensive</Typography> 
          <Typography  >software solutions and digital marketing services,we empower business to thrive in the ever-</Typography>
        <Typography>evolving landscape of the online world.</Typography>
  </Box>

        </Box>

      </Box>

      <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',width:{md:'80%',sm:'90%',xs:'90%'}}}>
       <Typography variant='h2'  sx={{fontWeight:'700',fontFamily:'poppins',fontSize:{lg:'3rem',md:'2.rem',sm:'2rem',xs:'1.2rem'},margin:{sm:'30px 0px',xs:'10px 0px'},color:'#38B6FF'}}>About HyperNxt Technology</Typography>

       <Box component={'div'} sx={{fontSize:'22px',margin:{sm:'30px 0px',xs:'10px 0px'},color:'black',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',
    '& > p':{fontSize:{lg:'22px',md:'17px',sm:'13px',xs:'6px'}}}}>
        <Typography >At HyperNxt Technology, we are dedicated to revolutionizing the digital landscape. With a</Typography> 
          <Typography  >passion for innovation and a commitment to excellence, we empower businesses to achieve </Typography>
        <Typography>remarkable growth in the online realm.</Typography>
       </Box>

      </Box>
      </Box>

      {/* ----------- */}

      <Box sx={{width:'100%',minHeight:'10vh',display:'flex',justifyContent:'center',alignItems:'center',margin:{sm:'70px 0px',xs:'30px 0px'}}}>
        <Box sx={{width:'80%',height:{xl:'900px',lg:'900px',md:'730px',sm:'555px',xs:'auto'},background:'rgba(56, 182, 255, 0.35)',display:'flex',alignItems:{sm:'flex-end',xs:'flex-start'}}}>
            <Grid container sx={{height:'100%',display:'flex',justifyContent:'flex-start',alignItems:'flex-end'}}>
              <Grid item sm={3.5} xs={12} sx={{height:'100%',display:'flex',justifyContent:'flex-start',alignItems:'flex-end'}}>
              <Box className="namessssssss" sx={{width:'100%',height:{xl:'653px',lg:'500px',md:'400px',sm:'470px',xs:'120px'},display:'flex',justifyContent:'center',alignItems:'center',

'& > img':{width:{xl:'270px',lg:'220px',md:'150px',sm:'120px',xs:'100px'},height:{xl:'255px',lg:'200px',md:'120px',sm:'90px',xs:'80px'}},zIndex:'2'}}>

 {/* <img src={H_logo} alt="H_logo" /> */}
</Box>
              </Grid>
              <Grid item sm={8} xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'flex-start',flexDirection:'column',paddingLeft:{xl:'50px',lg:'40px',md:'30px',sm:'20px',xs:'0px'},padding:{xs:'20px 10px'}}}>

                <Box sx={{'& > p':{fontSize:{xl:'40px',lg:'32px',md:'24px',sm:'16px',xs:'13px',},fontWeight:'600',fontFamily:'poppins',lineHeight:{xl:'84px',lg:'70px',md:'49px',sm:'30px',xs:'20px'}}}}>
                  <Typography className='gp_text'>Our Mission</Typography>
                </Box>

                <Box sx={{display:'flex',flexDirection:'column','& > p':{fontSize:{xl:'25px',lg:'22px',md:'17px',sm:'14px',xs:'9px'},fontWeight:'400',fontFamily:'poppins',lineHeight:{xl:'37.5px',lg:'36px',md:'30px',sm:'20px',xs:'10px'}}}}>
                  <Typography>
                  Our mission is to redefine success by leveraging cutting-edge technology and strategic insights. We strive to be the catalyst that propels businesses into the digital forefront, enabling them to flourish and thrive.
                  </Typography>
                </Box>

                <Box sx={{'& > p':{fontSize:{xl:'40px',lg:'32px',md:'24px',sm:'16px',xs:'13px',},fontWeight:'600',fontFamily:'poppins',lineHeight:{xl:'84px',lg:'70px',md:'49px',sm:'30px',xs:'20px'}}}}>
                  <Typography className='gp_text'>What We Do</Typography>
                </Box>

                <Box sx={{display:'flex',flexDirection:'column','& > p':{fontSize:{xl:'25px',lg:'22px',md:'17px',sm:'14px',xs:'9px'},fontWeight:'400',fontFamily:'poppins',lineHeight:{xl:'37.5px',lg:'36px',md:'30px',sm:'20px',xs:'10px'}}}}>
                  <Typography>
                  At HyperNxt Technology, we specialize in comprehensive digital solutions that drive tangible results. From bespoke software development to data-driven marketing strategies, we offer a suite of services tailored to elevate your business in the digital sphere.
                  </Typography>
                </Box>

                <Box sx={{'& > p':{fontSize:{xl:'40px',lg:'32px',md:'24px',sm:'16px',xs:'13px',},fontWeight:'600',fontFamily:'poppins',lineHeight:{xl:'84px',lg:'70px',md:'49px',sm:'30px',xs:'20px'}}}}>
                  <Typography className='gp_text'>Our Approach</Typography>
                </Box>

                <Box sx={{display:'flex',flexDirection:'column','& > p':{fontSize:{xl:'25px',lg:'22px',md:'17px',sm:'14px',xs:'9px'},fontWeight:'400',fontFamily:'poppins',lineHeight:{xl:'37.5px',lg:'36px',md:'30px',sm:'20px',xs:'10px'}}}}>
                  <Typography>
                  We believe in a collaborative approach, working closely with our clients to understand their unique needs and objectives. Our team of experts combines technical prowess with creative ingenuity, delivering solutions that resonate with your audience and drive sustainable growth.
                  </Typography>
                </Box>

              </Grid>
            </Grid>
        </Box>
      </Box>

      <BuildTogether/>
    </Layout>
  )
}

export default AboutUs
