export const carrier_data = [
  {
    header: 'MERN Stack Developer',
    about: 'We are seeking a skilled MERN Stack Developer to contribute to the development of cutting-edge web applications.'
  },
  // {
  //   header: 'Java Developer',
  //   about: 'Join our dynamic team as a Java Developer and work on exciting projects that push the boundaries of technology.'
  // },
  {
    header: 'Business Development Executive',
    about: 'Are you a go-getter with a passion for business growth? Join us as a Business Development Executive and be a key player in our expansion strategy.'
  },
  {
    header: 'Digital Marketing Executive',
    about: 'Shape our online presence and drive digital initiatives as a Digital Marketing Executive. Join us in creating impactful marketing campaigns.'
  },
]