import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "../../Styles/TestimonialStyles.css";
import { EffectCoverflow, Pagination, Autoplay } from "swiper/modules";
import testimonial1 from "../../Assets/29.png";
import testimonial2 from "../../Assets/20.png";
import testimonial3 from "../../Assets/13.png";
import testimonial4 from "../../Assets/3 (1).png";
import testimonial5 from "../../Assets/31.png";
import testimonial6 from "../../Assets/30.png";
import { Box, Typography } from "@mui/material";
import { Margin } from "@mui/icons-material";


const TestimonialPage = () => {
  return (
    <>
      
    <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <defs>
  <linearGradient id="paint0_linear_2875_253" x1="-3.37365e-06" y1="-15.1094" x2="1383.89" y2="1122.81" gradientUnits="userSpaceOnUse">
<stop stop-color="#02A9F7"/>
<stop offset="0.65" stop-color="#9747FF" stop-opacity="0.8"/>
</linearGradient>
  </defs>
  <path fill="url(#paint0_linear_2875_253)" fill-opacity="1" d="M0,96L60,96C120,96,240,96,360,133.3C480,171,600,245,720,256C840,267,960,213,1080,202.7C1200,192,1320,224,1380,240L1440,256L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
</svg>
    </div>
    <Box sx={{marginBottom:{md:'0px'},background:'linear-gradient(112deg, #02A9F7 -10.87%, rgba(151, 71, 255, 0.80) 61.48%)',padding:'30px'}}>
   
      {/* <div className="TestimonalPage_content">
        <h1 style={{color:"black",marginLeft:'-2%',fontSize:{lg:'36px',xs:'20px'}}}>
          Our Client <span className="testimonialpage_span" sx={{ontSize:{lg:'36px',xs:'20px'}}}>Testimonial</span>
        </h1>
      </div> */}

        <Box sx={{width:'100%',padding:{xs:'0px 20px'}}}>
        <Typography variant='h4' sx={{fontWeight:'700',fontFamily:'poppins',fontSize:{lg:'50px',md:'40px',sm:'27px',xs:'22px'}}} component={'div'}>Our Client <Typography variant='h4'  component={'span'} sx={{fontWeight:'700',fontFamily:'poppins',color:'#0abde3',fontSize:{lg:'50px',md:'40px',sm:'27px',xs:'22px'}}}>Testimonials</Typography></Typography>
        </Box>

      <Swiper
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 1,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        loop={true}
        spaceBetween={250}
        pagination={true}
        modules={[EffectCoverflow, Autoplay]}
        className="mySwiper"
        id="Myswipey"
      >
        <SwiperSlide>
          <div className="Testimonialpage_box">
            <img src={testimonial1} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img src={testimonial2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={testimonial3} alt="" />
                </SwiperSlide>
        <SwiperSlide>
        <img src={testimonial4} alt="" />
                </SwiperSlide>
        <SwiperSlide>
        <img src={testimonial5} alt="" />        </SwiperSlide>
        <SwiperSlide>
        <img src={testimonial6} alt="" />        </SwiperSlide>
      </Swiper>
    
    </Box>
    <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <defs>
  <linearGradient id="paint0_linear_2875_254" x1="4.50068e-05" y1="-946.298" x2="1356.99" y2="287.244" gradientUnits="userSpaceOnUse">
  <stop stop-color="#02A9F7"/>
<stop offset="0.75" stop-color="#9747FF" stop-opacity="0.8"/>
</linearGradient>
  </defs>
  <path fill="url(#paint0_linear_2875_254)" fill-opacity="1" d="M0,96L60,96C120,96,240,96,360,133.3C480,171,600,245,720,256C840,267,960,213,1080,202.7C1200,192,1320,224,1380,240L1440,256L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
</svg>


  </div>
    </>
  );
};
export default TestimonialPage;
