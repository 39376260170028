import React, { useEffect, useState } from "react";
import "../../Styles/HeaderStyles.css";
import { AppBar, Box, Collapse, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Toolbar, Typography } from '@mui/material';
import { NavLink ,Link} from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import logo_icon from "../../Assets/hypernextlogo-removebg-preview.png";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// ------------------------------------

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [serviceOpen, setServiceOpen] = useState(false);

  // sidebar

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  // -------------

  const handleServiceOpen = () => {
    setServiceOpen(!serviceOpen);
  };

  const handleDrawertoggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawertoggle}  sx={{textAlign:'center'}}>

      <Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
      <Typography variant='h6' className='header_logo' sx={{flexGrow:1,my:2,width:'100%',display:"flex",justifyContent:'center',alignItems:'center'}}>
      <NavLink to={'/'}>
      <img 
        src={logo_icon} 
        alt="HyperNxt" 
        style={{width: '150px', height: '90px', }} // Added a border for visibility
      />
    </NavLink>
      </Typography>
      </Box>
      <Divider />

      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          padding: "8px 0px",
        }}
        component="nav"
      >
        <ListItemButton onClick={handleClickOpen}>
          <ListItemText primary="Services" onClick={handleServiceOpen} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton href={"/software_solutions"} sx={{ pl: 3 }}>
              <ListItemText primary="Software Solutions" />
            </ListItemButton>

            <ListItemButton href={"/brand_promotions"} sx={{ pl: 3 }}>
              <ListItemText primary="Brand Promotions" />
            </ListItemButton>

            <ListItemButton href={"/digital_marketing"} sx={{ pl: 3 }}>
              <ListItemText primary="Digital Marketing" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton href="/products">
          <ListItemText primary="Products" />
        </ListItemButton>

        <ListItemButton href="/about">
          <ListItemText primary="About Us" />
        </ListItemButton>

        <ListItemButton href="/contact">
          <ListItemText primary="Contact Us" />
        </ListItemButton>

        <ListItemButton href="/signin">
          <ListItemText primary="Sign In" />
        </ListItemButton>
      </List>
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          background: "transparent",
          position: "fixed",
          width: "100%",
          minHeight: "10vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "999",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          component="nav"
          sx={{
            width: "100%",
            height: "10vh",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            background: "#fff",
            padding: { md: "0px 20px", sm: "0px 10px" },
          }}
        >
          <Typography
            variant="h6"
            component={"div"}
            sx={{
              flexGrow: 1,
              "@media (max-width:600px)": {
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                // marginLeft: "16px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: { md: "50px", sm: "10px" },
              }}
            >
              <NavLink to={"/"}>
                {" "}
                <img
                  src={logo_icon}
                  alt="HyperNxt"
                  className="header_logo"
                />
              </NavLink>

              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                sx={{
                  margin: "2",
                  display: { xs: "block", sm: "none" },
                  position: "absolute",
                  right: "6px",
                }}
                onClick={handleDrawertoggle}
              >
                <MenuIcon color="primary" />
              </IconButton>
            </Box>
          </Typography>

          <Box
            className="navigation_menu"
            sx={{
              display: { xs: "none", sm: "block", minHeight: "10vh" },
              marginRight: { lg: "40px" },
            }}
          >
            <ul>
              <li>
                <NavLink style={{ position: "relative" }}>
                  Services
                  <ArrowDropDownIcon
                    sx={{ position: "absolute", top: "-2px" }}
                  />
                </NavLink>

                <ul className="drop_down">
                  {/* <li><Link to={"/sms_service"}><ArrowForwardIcon className='dropdown_arrow'/>SMS Services</Link></li>
                     <li><Link to={"/bulk_whatsapp"}><ArrowForwardIcon className='dropdown_arrow'/>Bulk Whatsapp</Link></li> */}
                  <li>
                    <Link to={"/software_solutions"}>
                      <ArrowForwardIcon className="dropdown_arrow" />
                      Software Solutions
                    </Link>
                  </li>
                  <li>
                    <Link to={"/brand_promotions"}>
                      <ArrowForwardIcon className="dropdown_arrow" />
                      Brand Promotions
                    </Link>
                  </li>
                  <li>
                    <Link to={"/digital_marketing"}>
                      <ArrowForwardIcon className="dropdown_arrow" />
                      Digital Marketing
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink activeClassName="active" to={"/products"}>
                  Products
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to={"/about"}>
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to={"/contact"}>
                  Contact Us
                </NavLink>
              </li>

              <button className="signin_btn">
                <Link to={"/signin"}>Sign In</Link>
              </button>
            </ul>
          </Box>
        </Box>

        <Box component="nav">
          <Drawer
            variant="temporary"
            anchor="right"
            open={mobileOpen || serviceOpen}
            onClose={handleDrawertoggle}
            sx={{
              display: { sx: "block", sm: "none" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: "180px" },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </>
  );
};

export default Header;
