import React from "react";
import "./NSignUpPage.css";
import Layout from "../Components/Layout/Layout";
import logo1 from "../Assets/Googleimage.png";
import logo2 from "../Assets/facebookImage.png";
import logo4 from "../Assets/Appleimage.png";
import newlogo from "../Assets/new main logo.png";
const SignIn = () => {
  return (
    <Layout>
      <>
        <div id="NsignUppage_main_contact_body">
          <div id="NsignUppage_Form_Container">
            <div id="Nsignuppage-Form_ContactIfo">
              <div id="NSignUppage_Form_box">
                <div id="NSignUppage_Form_text">
                  {/* <img src={logo3} alt="" id="NSignUppage_Images" /> */}
                  <img src={newlogo} alt="" id="NSignUppage_Images" />
                  <h2 id="NSignUppage_text1">Elevate & Innovate</h2>
                  <h2 id="NSignUppage_text1">
                    Shaping the Future with Bold Ideas
                  </h2>
                </div>
              </div>
            </div>
            {/* NSIGNUPPAGE FORM */}
            <div id="NsignUppage_Contact_Form_body">
              <div id="NsignUppage_All-form">
                <form action="" id="NsignUppage_Form">
                  <div id="NsignUppage_Form_title">Sign Up</div>
                  <div id="NsignUppage_form_title_two">
                    Already have an account?{" "}
                    <span id="NsignUpapge_span_One">Sign In</span>
                  </div>
                  <div id="NsignUpPage_input-box1">
                    <label htmlFor="" id="NsignUpPage_label_One">
                      Name
                    </label>
                    <br />
                    <input type="text" id="NsignUpPage_First_InPut_Box" />
                  </div>
                  <div id="NsignUpPage_input_box2">
                    <label htmlFor="" id="NsignUpPage_label_two">
                      Email I'D
                    </label>
                    <br />
                    <input type="email" id="NsignUpPage_Second-input-Box" />
                  </div>
                  <div id="NsignUpPage_input_box2">
                    <label htmlFor="" id="NsignUpPage_label_three">
                      Password
                    </label>
                    <br />
                    <input type="text" id="NsignUpPage_Second-input-Box" />
                  </div>
                  <div id="NSignUpapage_Submit_Button">
                    <button id="NSignUpapage_Button_One">Sign Up</button>
                  </div>
                  <div id="NSignUpapage_or">
                    <hr id="NSignUpapage_hr1" />{" "}
                    <p id="NSignUpapage_or_para">OR</p>{" "}
                    <hr id="NSignUpapage_hr-two" />
                  </div>
                  <div id="NSignUpapage_social_icons">
                    <div id="NSignUpapage_First_icon">
                      <img src={logo1} alt="" id="NSignUpapage_icon_Image1" />
                    </div>
                    <div id="NSignUpapage_First_icon">
                      <img src={logo2} alt="" id="NSignUpapage_icon_Image1" />
                    </div>
                    <div id="NSignUpapage_First_icon">
                      <img src={logo4} alt="" id="NSignUpapage_icon_Image1" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};
export default SignIn;
