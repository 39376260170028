import React from 'react'
import Layout from '../Components/Layout/Layout';
import { Box, Typography } from '@mui/material';
import newsroom_banner from "../Assets/newsroom_banner.png";

const Newsroom = () => {
  return (
    <Layout>
      <Box sx={{width:'100%',minHeight:'50vh',background:`url(${newsroom_banner}) no-repeat`,backgroundPosition:'center',backgroundSize:'cover',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
        <Typography variant='h3' sx={{fontWeight:'700',fontFamily:'poppins',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'20px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'33px'},}} className='gp_text'>Newsroom</Typography>

        <Typography sx={{fontWeight:'500',fontFamily:'poppins',fontSize:{lg:'25px',md:'20px',sm:'14px',xs:'10px'},lineHeight:{lg:'37.5px',md:'25px',sm:'22px',xs:'20px'},color:'#fff'}}>Unlock Your Business's Potential with Comprehensive Digital Solutions</Typography>
      </Box>

      <Box  sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
      <Box sx={{'& > .sms_para':{margin:{lg:'16px 0px',md:'14px 0px',sm:'12px 0px',xs:'9px 0px'}},'& > .sms_para > p':{fontWeight:'400',fontFamily:'poppins',fontSize:{xl:'25px',lg:'22px',md:'16px',sm:'12px',xs:'9px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'15px'}},width:'90%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>

      <Box className='sms_para'>
          <Typography>
          In an era dominated by digital interactions, establishing a robust online presence is not just an option; it's a necessity. At HyperNxt, we offer a suite of services that go beyond conventional marketing, propelling your business to new heights. From brand promotion and targeted advertising to website building and comprehensive digital marketing strategies, we are your one-stop solution for a thriving online enterprise.          
          </Typography>
         
      </Box>

        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',fontFamily:'poppins',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'}}}>Brand Promotion</Typography>
          </Box>

          <Typography>
            Your brand is more than just a logo; it's an identity that speaks to your audience. Our brand promotion services focus on creating a compelling narrative for your business. We craft engaging stories, design eye-catching visuals, and implement strategic campaigns that resonate with your target market. Whether you're brand stands out in the digital landscape.
          </Typography>
      
        </Box>
        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',fontFamily:'poppins',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'}}}>Ad Shooting and Digital Marketing</Typography>
          </Box>

          <Typography>
          Visual storytelling is a powerful tool in the digital age. Our ad shooting services are tailored to capture the essence of your brand and create visually stunning advertisements. From concept to execution, we ensure that your ads leave a lasting impression. Pair this with our digital marketing expertise, and you have a winning combination. We leverage social media platforms, search engines, and targeted ad placements to maximize your reach and drive meaningful engagement.
          </Typography>
        
        </Box>

        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',fontFamily:'poppins',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'}}}>Website Building</Typography>
          </Box>

          <Typography>
          Your website is often the first interaction a potential customer has with your business. Make it count with our professional website building services. Our team of skilled developers and designers create responsive, user-friendly websites that not only look great but also function seamlessly. Whether you need platform, a corporate website, or a portfolio, we have the expertise to bring your vision to life. an e-commerce
          </Typography>

        </Box>

        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',fontFamily:'poppins',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'}}}>Taking Your Business Online</Typography>
          </Box>

          <Typography>
          Embrace the digital frontier with our comprehensive online solutions. From setting up e-commerce platforms to implementing secure payment gateways, we ensure that your business not only survives but thrives in the   online space. Our strategies are data-driven, ensuring that every decision is backed by insights to maximize your online success.
          </Typography>
        </Box>

        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',fontFamily:'poppins',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'}}}>Why Choose HyperNxt?</Typography>
          </Box>

          <Typography>
          Holistic Approach: We don't just focus on one aspect; we integrate brand promotion, ad shooting, website building, and digital marketing into a seamless strategy. Experienced Team: Our team comprises seasoned professionals who understand the nuances of the digital landscape. Custom Solutions: We tailor our services to meet the unique needs of your business, ensuring that you get the most effective solutions.
          </Typography>
        
        </Box>

        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',fontFamily:'poppins',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'}}}>Ready to Transform Your Business?</Typography>
          </Box>

          <Typography>
            Take the leap into the digital realm with HyperNxt. Whether you're a small business looking to make a mark   or a large enterprise seeking a digital facelift, we have the expertise and passion to make it happen. Let's not just meet expectations; let's exceed them together.
          </Typography>
        </Box>

        <Box className='sms_para'>
          <Typography>
          Contact us today to kickstart your journey to digital success. Your brand deserves to be in the spotlight, and  we're here to make it happen.           
          </Typography>
        </Box>

        <Box  sx={{width:'100%',display:'flex',alignItems:'flex-start',justifyContent:'flex-start',textAlign:'start',marginBottom:'10px'}}>
        <Typography sx={{fontWeight:'700',fontFamily:'poppins',fontSize:{lg:'30px',md:'20px',sm:'16px',xs:'9px'},lineHeight:{lg:'45px',md:'30px',sm:'20px',xs:'20px'},color:'#38B6FF'}}>
        HyperNxt - Empowering Your Digital Presence.
        </Typography>
       </Box>
       
      </Box>
      </Box>
    </Layout>
  )
}

export default Newsroom
