import React, { useEffect, useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box } from "@mui/material";

const GoTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const goTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  // useEffect(()=>{
  //   window.addEventListener('scroll',listenToScroll);
  //   return () => window.removeEventListener('scroll',listenToScroll);
  // },[]);

  const listenToScroll = () => {
    let heightToHidden = 300;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    // console.log("winScroll",winScroll);
    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <>
      <Box>
        {isVisible && (
          <Box
            onClick={goTop}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: { md: "3rem", sm: "2.8rem", xs: "2.5rem" },
              height: { md: "3rem", sm: "2.8rem", xs: "2.5rem" },
              position: "fixed",
              top: "85vh",
              right: "5%",
              zIndex: "1900",
              cursor: "pointer",
              // ,background:'#27ae60'
              background: "transparent",
              border: "1px solid grey",
              borderRadius: "50%",
            }}
          >
            <ArrowUpwardIcon
              sx={{
                fontSize: { md: "2rem", sm: "1.8rem", xs: "1.5rem" },
                color: "#487eb0",
                //  color:'#fff'
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default GoTop;
