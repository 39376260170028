// import React, { useState } from 'react';
// import {AccordianData} from "../../Data/AccordianData";
// import { Box, Typography } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import RemoveIcon from '@mui/icons-material/Remove';

// const Accordian = () => {
//   const[selected,setSelected] = useState(null);

//   const handleSelection = (getCurrentId) =>{
//     console.log(getCurrentId);
//     setSelected(getCurrentId === selected? null:getCurrentId);
//   }
//   return (
//     <>
      
//       <Box sx={{width:'100%',minHeight:'100vh',display:'flex',justifyContent:'center',alignItems:'center',padding:'20px 0px'}}>
//         <Box sx={{width:'80%'}}>
//           <Typography variant='h4' sx={{fontWeight:'800',margin:"20px 0px",fontSize:{lg:'2.2rem',md:'2rem',sm:'1.8rem',xs:'0.8rem'}}}>Frequently Asked Questions</Typography>
          
//         <Box sx={{width:'100%'}}>
//           {
//             AccordianData?.map((item)=>(
//               <Box sx={{background:'antiquewhite',marginBottom:'12px',cursor:'pointer'}}>
//                 <Box onClick={()=>handleSelection(item.id)} sx={{background:'#dfe6e9',marginBottom:'10px',padding:{lg:'10px 20px',xs:'5px 10px'},display:'flex',justifyContent:'space-between',alignItems:'center'}}>
//                 <Typography sx={{fontSize:{lg:'1.5em',md:'1.2em',sm:'0.8em',xs:'0.6em'},fontWeight:'500'}}>{item.question}</Typography>
//                 <Typography component={'span'} sx={{}}>{selected?<RemoveIcon/>:<AddIcon/>}</Typography>
//                 </Box>
//                 {
//                   selected === item.id? 
//                   <Typography sx={{padding:'5px 10px',fontSize:{lg:'1rem',md:'0.9rem',sm:'0.8rem',xs:'0.6rem'}}}>
//                     {item.answer}
//                   </Typography>: null
//                 }
//               </Box>
//             ))
//           }
//         </Box>
            
//         </Box>
//       </Box>
//     </>
//   )
// }

// export default Accordian


import React, { useState } from 'react';
import {AccordianData} from "../../Data/AccordianData";
import "../../Styles/AccordianStyles.css";

const Accordian = () => {

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return ( 
    <>
       <section>
      <div className="faq-accordion">
        <h1 className="ques">Frequently asked Questions-</h1>
        {AccordianData.map((faq, index) => (
          <div className="faq-item" key={index}>
            <div
              className={`faq-question ${
                activeIndex === index ? "active" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <h3>{faq.question}</h3>
              <span>{activeIndex === index ? "-" : "+"}</span>
            </div>
            <div
              className={`faq-answer ${activeIndex === index ? "active" : ""}`}
            >
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
    </>
  )
}

export default Accordian
