import React from 'react'
import Layout from '../Components/Layout/Layout';
import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import "../Styles/ContactUsStyles.css";
import explore_carrer from "../Assets/explore_carrer.png";
import EastIcon from '@mui/icons-material/East';
import { useState } from 'react';
import { useMemo } from 'react';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    message: ''
  });
  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!formData.name || !formData.email || !formData.number || !formData.message) {
      alert('Please fill in all fields.');
      return;
    }
  
    showPleaseWaitMessage();
  
    const formDataToSubmit = new FormData();
    formDataToSubmit.append('name', formData.name);
    formDataToSubmit.append('number', formData.number);
    formDataToSubmit.append('email', formData.email);
    formDataToSubmit.append('message', formData.message);
  
    fetch('https://script.google.com/macros/s/AKfycbyRNuF8sDfmfFbEWsy6Xmqf8Cv6SDbW7mpaiTcJ43ly37onNNnqtKvwaxvkmQ3x1oe-/exec', {
      method: 'POST',
      body: formDataToSubmit
    })
    .then(response => response.text())
    .then(data => {
      console.log(data); // Handle response from server if needed
      alert('Form submitted successfully!');
      setFormData({ name: '', number: '', message: '', email: '' });
      // setShowForm(false)
    })
    .catch(error => {
      console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    })
    .finally(() => {
      hidePleaseWaitMessage();
    });
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const memoizedFormData = useMemo(() => ({
    name: formData.name,
    email: formData.email,
    number: formData.number,
    message: formData.message
  }), [formData]);
  const showPleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.style.whiteSpace = 'nowrap';
    submitButton.innerText = 'Please wait...';

    submitButton.disabled = true;
  };

  const hidePleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Submit';
    submitButton.disabled = false;
  };
  
  return (
    <Layout>
      <Box sx={{width:'100%',minHeight:'100vh'
      ,paddingTop:{md:'100px',sm:'70px',xs:'30px'}
      ,background:'#fff',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
        <Box sx={{width:'80%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
          <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <Typography sx={{fontSize:{xl:'25px',lg:'20px',md:'18px',sm:'16px',xs:'10px'},lineHeight:{xl:'37.5px',lg:'37.5px',md:'37.5px',sm:'37.5px',xs:'20px'},fontWeight:'400',color:'#0B0B0BB2',fontFamily:'poppins'}}>Connect with us</Typography>
            <Typography className='gp_text' sx={{fontSize:{xl:'60px',lg:'50px',md:'47px',sm:'30px',xs:'14px'},lineHeight:{xl:'90px',lg:'90px',md:'90px',sm:'60px',xs:'20px'},fontWeight:'700',fontFamily:'poppins'}}>Let’s Build Future Together</Typography>
            <Typography sx={{fontSize:{xl:'25px',lg:'20px',md:'18px',sm:'16px',xs:'8px'},lineHeight:{xl:'37.5px',lg:'30px',md:'30px',sm:'37.5px',xs:'20px'},fontWeight:'400',color:'#0B0B0BB2',fontFamily:'poppins'}}>Get in Touch: Together, Let's Shape a Prosperous Future.</Typography>
          </Box>

          <Box sx={{margin:'30px 0px',width:'80%'}}>
            <form action="" className='contact_form'>
            <input type="text" placeholder="Name" id="name" name="name" value={memoizedFormData.name} onChange={handleChange} required />
            <input type="email"  placeholder="Email I'd"  id="email" name="email" value={memoizedFormData.email} onChange={handleChange} required />
                <input type="number"  placeholder="Mobile Number" id="contactNumber" name="number" value={memoizedFormData.number} onChange={handleChange} required />
                <textarea  placeholder="Message" required=""  value={memoizedFormData.message}  onChange={handleChange} style={{color:"#000"}} cols="50" rows="16" id="textarea" name="message">          </textarea>

                  <Box sx={{margin:'30px 0px',width:'100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',
                  '& > p':{fontSize:{xl:'25px',lg:'24px',md:'18px',sm:'15px',xs:'7px'}},fontFamily:'poppins',fontWeight:'400',color:'#0B0B0BB2'}}>
                  <Typography>By submitting, you agree to receive important updates and</Typography>
                  <Typography>insights via email and call from HyperNxt.</Typography>
                  </Box>
                  <button className='contact_btn'  type="submit" id="submitButton" onClick={handleSubmit} >Submit<EastIcon sx={{marginLeft:'3px',fontSize:{md:'24px',sm:'20px',xs:'14px'}}}/></button>

                {/* <button className='contact_btn' onClick={handleSubmit} ><Link>Submit<EastIcon sx={{marginLeft:'3px',fontSize:{md:'24px',sm:'20px',xs:'14px'}}}/></Link></button> */}
              </form>
          </Box>
        </Box>

        <Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',margin:{xl:'150px 0px',lg:'140px 0px',xs:'20px 0px'}}}>
      <Box className='explore_box'>
          <Grid container>
              <Grid item xs={7}  className='explore_content'>
                <Typography sx={{fontSize:{xl:'60px',lg:'48px',md:'35px',sm:'20px',xs:'12px'},fontFamily:'poppins',fontWeight:'700'}}>Grab a seat, we’re hiring.</Typography>
                <Box sx={{'& > p':{fontSize:{xl:'25px',lg:'21px',md:'14px',sm:'8.5px',xs:'5px'},fontFamily:'poppins',fontWeight:'400',color:'#0B0B0B'},margin:{md:'20px 0px',sm:'10px 0px',xs:'5px 0px'}}}>
                <Typography>Please visit our careers page for career related enquiries,</Typography>
                <Typography>opportunities and current job openings.</Typography>
                </Box>
                <Box className='explore_btn'>
                  <Typography sx={{fontSize:{xl:'22px',md:'20px',sm:'0.6rem',xs:'0.4rem'},fontWeight:'400',color:'#fff',display:'flex',justifyContent:'center',alignItems:'center'}}>Explore HyperNxt Careers <EastIcon sx={{marginLeft:'3px',fontSize:{sm:'15px',xs:'10px'}}}/></Typography>
                </Box>
              </Grid>
              <Grid item xs={4} sx={{width:'100%'}} className='explore_img_box'>
                <img src={explore_carrer} alt="explore_carrer" />
              </Grid>
          </Grid>
      </Box>
       </Box>

       <Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginBottom:{xl:'50px',lg:'50px',xs:'20px'}}}>
          <Box className='our_office_visit_box'>
              <Typography  sx={{fontSize:{xl:'25px',lg:'22px',md:'20px',sm:'16px',xs:'10px'},fontWeight:'400',fontFamily:'poppins',color:'#0B0B0BB2'}}>Visit Us</Typography>
              <Typography className='gp_text' sx={{fontSize:{xl:'60px',lg:'55px',md:'40px',sm:'30px',xs:'20px'},fontWeight:'700',fontFamily:'poppins',marginBottom:'20px'}}>Our Office</Typography>
              <Box className='office_address_box'>
                 <Box sx={{width:'90%',display:'flex',flexDirection:'column'}}>
                 <Box sx={{'& > p':{fontSize:{xl:'30px',lg:'24px',md:'18px',sm:'12px',xs:'12px'},fontWeight:'500',fontFamily:'poppins',lineHeight:{xl:'45px',lg:'35px',md:'30px'}}}}>
                 <Typography>HyperNxt Technology PVT LTD </Typography>
                <Typography>17, Outer Ring Rd,</Typography>
                <Typography>Near Reliance Fresh, KEB Colony,</Typography>
                <Typography>New Gurappana Palya, 1st Stage, BTM Layout, Bengaluru, Karnataka </Typography>
                <Typography>560029.</Typography>
                 </Box>

                 <Box sx={{margin:{lg:'20px 0px',md:'10px 0px'},'& > p >a':{fontSize:{xl:'30px',lg:'24px',md:'18px',sm:'12px',xs:'12px'},color:'#38B6FF',lineHeight:{xl:'45px',lg:'35px',md:'30px'}}}}>
                 <Typography><Link>info@thehypernxt.com</Link></Typography>
                 </Box>
                  <Box  sx={{margin:{lg:'20px 0px',md:'10px 0px'},'& > p ':{fontSize:{xl:'30px',lg:'24px',md:'18px',sm:'12px',xs:'12px'},lineHeight:'45px'}}}><Typography>+91 82498 46606</Typography></Box>
                 </Box>
              </Box>
          </Box>
      </Box>

      </Box>

      

      

    </Layout>
  )
}

export default ContactUs
