import React from 'react'
import Layout from '../Components/Layout/Layout';
import { Box, Typography } from '@mui/material';
import privacy_policy_banner from "../Assets/privacy_policy_banner.png";
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Box sx={{width:'100%',minHeight:'50vh',background:`url(${privacy_policy_banner}) no-repeat`,backgroundPosition:'center',backgroundSize:'cover',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
        <Typography variant='h3' sx={{fontWeight:'700',fontFamily:'poppins',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'20px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'30px'},}} className='gp_text'>Privacy Policy</Typography>

        <Typography sx={{fontWeight:'500',fontFamily:'poppins',fontSize:{lg:'25px',md:'20px',sm:'12px',xs:'7px'},lineHeight:{lg:'37.5px',md:'30px',sm:'18px',xs:'18px'},color:'#fff'}}>Welcome to HyperNxt TECHNOLOGY PVT LTD ("we", "us", or "our"). This Privacy Policy </Typography>

        <Typography sx={{fontWeight:'500',fontFamily:'poppins',fontSize:{lg:'25px',md:'20px',sm:'12px',xs:'7px'},lineHeight:{lg:'37.5px',md:'30px',sm:'18px',xs:'18px'},color:'#fff'}}>outlines how we collect, use, and protect the information you provide when using our IT </Typography>

        <Typography sx={{fontWeight:'500',fontFamily:'poppins',fontSize:{lg:'25px',md:'20px',sm:'12px',xs:'7px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'18px'},color:'#fff'}}>services and consulting. </Typography>
      </Box>

      <Box  sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
      <Box sx={{'& > .sms_para':{width:'100%',margin:{lg:'16px 0px',md:'14px 0px',sm:'12px 0px',xs:'9px 0px'}},'& > .sms_para > p':{fontSize:{xl:'25px',lg:'22px',md:'16px',sm:'12px',xs:'9px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'15px'},fontWeight:'400',fontFamily:'poppins'},width:'90%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column','& > .sms_para > p > span > a':{fontSize:{xl:'25px',lg:'22px',md:'16px',sm:'12px',xs:'9px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'15px'},color:'#38B6FF'}}}>

        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',fontFamily:'poppins',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'}}}>Information We Collect</Typography>
          </Box>
         <Box sx={{'& > ul':{paddingLeft:'35px'},'& > ul > li':{fontSize:{xl:'25px',lg:'20px',md:'16px',sm:'12px',xs:'9px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'15px'},fontWeight:'400',fontFamily:'poppins'}}}>
         <Typography sx={{fontWeight:'700',fontFamily:'poppins',margin:'10px 0px',color:'#0B0B0B',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'}}}>We may collect the following types of information:</Typography>
         <ul>
          <li>Client Information: Names, contact details, and other information provided during consulting engagements.</li>
          <li>Technical Data: Information related to IT systems, configurations, and usage.</li>
          <li>Log Data: Details about your interactions with our services, including IP addresses and device information.</li>
         </ul>
         </Box>
        </Box>

        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',fontFamily:'poppins',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'}}}>How We Use Your Information</Typography>
          </Box>
         <Box sx={{'& > ul':{paddingLeft:'35px'},'& > ul > li':{fontSize:{xl:'25px',lg:'20px',md:'16px',sm:'12px',xs:'9px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'15px'},fontWeight:'400',fontFamily:'poppins'}}}>
         <Typography sx={{fontWeight:'700',fontFamily:'poppins',margin:'10px 0px',color:'#0B0B0B',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'}}}>We use the collected information for the following purposes:</Typography>
         <ul>
          <li>Providing IT services and consulting tailored to your needs.</li>
          <li>Ensuring the security and confidentiality of client information.</li>
          <li>Improving our services and addressing technical issues.</li>
         </ul>
         </Box>
        </Box>

        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',fontFamily:'poppins',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'}}}>Client Confidentiality</Typography>
          </Box>

          <Typography>
          We understand the sensitivity of the information shared during consulting engagements. We are committed to maintaining the confidentiality of client data and ensuring its secure handling.
          </Typography>
        </Box>
        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',fontFamily:'poppins',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'}}}>Security Measures</Typography>
          </Box>

          <Typography>
          We implement industry-standard security measures to protect your data. However, no method of transmission over the internet or electronic storage is 100% secure.
          </Typography>
          
        </Box>

        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',fontFamily:'poppins',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'}}}>Third-Party Disclosure</Typography>
          </Box>

          <Typography>
            We do not sell or rent your personal information to third parties. However, we may share information with trusted partners and service providers to enhance our services.
          </Typography>
        </Box>

        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',fontFamily:'poppins',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'}}}>Refund Policy</Typography>
          </Box>

          <Typography>
          We strive to provide excellent services. If, for any reason, you are not satisfied with our services, we offer a refund within 30 days of your purchase. To request a refund, please contact us at info@HyperNxttech.com with your order details and the reason for your request.
          </Typography>
        </Box>

        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',fontFamily:'poppins',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'}}}>Contact Us</Typography>
          </Box>

          <Typography>HyperNxt Technology PVT LTD </Typography>
                <Typography>17, Outer Ring Rd,</Typography>
                <Typography>Near Reliance Fresh, KEB Colony,</Typography>
                <Typography>New Gurappana Palya, 1st Stage, BTM Layout, Bengaluru, Karnataka </Typography>
                <Typography>560029.</Typography>
        </Box>

        <Box className='sms_para'>
          <Typography>        
           If you have any questions about this Privacy Policy, please contact us at <Typography component={'span'} sx={{color:'#38B6FF'}}><Link>info@HyperNxttech.com</Link></Typography>.          
          </Typography>
        </Box>
       
      </Box>
      </Box>
    </Layout>
  )
}

export default PrivacyPolicy
