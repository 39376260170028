import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import SignIn from "./Pages/SignIn";
import PageNotFound from "./Pages/PageNotFound";
import Product from "./Pages/Product";
import SMS_Services from "./Pages/SMS_Services";
import Bulk_Whatsapp from "./Pages/Bulk_Whatsapp";
import Software_Solutions from "./Pages/Software_Solutions";
import Brand_Promotions from "./Pages/Brand_Promotions";
import Digital_Marketing from "./Pages/Digital_Marketing";
import TermOfServices from "./Pages/TermOfServices";
import Newsroom from "./Pages/Newsroom";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import CarrerOpertunities from "./Pages/CarrerOpertunities";
import Gallery from "./Pages/Gallery";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/products" element={<Product />} />
          <Route exact path="/about" element={<AboutUs />} />
          <Route exact path="/contact" element={<ContactUs />} />
          <Route exact path="/signin" element={<SignIn />} />
          <Route exact path="/sms_service" element={<SMS_Services />} />
          <Route exact path="/bulk_whatsapp" element={<Bulk_Whatsapp />} />
          <Route
            exact
            path="/software_solutions"
            element={<Software_Solutions />}
          />
          <Route
            exact
            path="/brand_promotions"
            element={<Brand_Promotions />}
          />
          <Route
            exact
            path="/digital_marketing"
            element={<Digital_Marketing />}
          />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/terms_services" element={<TermOfServices />} />
          <Route exact path="/newsroom" element={<Newsroom />} />
          <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route
            exact
            path="/carrer_opertunities"
            element={<CarrerOpertunities />}
          />

          <Route exact path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
