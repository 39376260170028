import React from 'react'
import Layout from '../Components/Layout/Layout';

import { Box, Typography } from '@mui/material';
import galler_img1 from "../Assets/gall_img1.jpg"
import galler_img2 from "../Assets/gall_img2.jpg"
import galler_img3 from "../Assets/gall_img3.jpg"
import galler_img4 from "../Assets/gall_img4.jpg"
import galler_img5 from "../Assets/gall_img6.jpg"
import galler_img6 from "../Assets/gall_img5.jpg"
import galler_img7 from "../Assets/gall_img7.jpg"
import galler_img8 from "../Assets/gall_img8.jpg"
import galler_img9 from "../Assets/gall_img9.jpg"
import galler_img10 from "../Assets/gall_img10.jpg"
import galler_img11 from "../Assets/gall_img11.jpg"
import galler_img12 from "../Assets/gall_img12.jpg"
import galler_img13 from "../Assets/gall_img13.jpg"
import galler_img14 from "../Assets/gall_img14.jpg"
import gall_vid1 from "../Assets/gall_vid1.mp4"
import gall_vid2 from "../Assets/gall_vid2.mp4"
import gall_vid3 from "../Assets/gall_vid3.mp4"
import gall_vid4 from "../Assets/gall_vid4.mp4"
import gall_vid5 from "../Assets/gall_vid5.mp4"

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const vidData=[
  {
    typ:"vid",
    src: gall_vid1,
    cols: 2,
    rows:2
  },
  {
    typ:"vid",
    src: gall_vid4,
    cols: 2,
    rows:2
  },
  
  {
    typ:"vid",
    src: gall_vid3,
    cols: 2,
    rows:2
  },
  {
    typ:"vid",
    src: gall_vid5,
    cols: 2,
    rows:2
  },
]
const itemData = [
  {
    typ:"img",
    img: galler_img14,
    cols: 4,
  },
  {
    typ:"img",
    img: galler_img8,
    cols: 2,
  },
  {
    typ:"img",
    img: galler_img9,
    cols: 2,
  },
  {
    typ:"img",
    img: galler_img7,
    cols: 2,
  },
  {
    typ:"img",
    img: galler_img1,
    rows: 3,
    cols: 2,
  },
  {
    typ:"img",
    img: galler_img12,
    cols: 2,
    rows:2,
  },
  {
    typ:"img",
    img: galler_img2,
    cols:4,
    rows:2
  },
  {
    typ:"img",
    img: galler_img10,
    cols: 2,
    rows:1
  },
  {
    typ:"img",
    img: galler_img3,
    cols: 2,
    rows:2,
  },
  {
    typ:"img",
    img: galler_img6,
    rows:1,
    cols: 2,
  },
];

const Gallery = () => {
  return (
    <Layout>
       <Box sx={{width:'100%',minHeight:{sm:'50vh',xs:'25vh'},display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
        <Typography variant='h3' sx={{fontWeight:'700',fontFamily:'poppins',fontSize:{lg:'60px',md:'50px',sm:'34px',xs:'17px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'30px'},fontFamily:'poppins'}} className='gp_text'>HyperNxt Technology PVT LTD.</Typography>
        
        <Typography variant='h3' sx={{fontWeight:'700',fontFamily:'poppins',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'17px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'30px'},fontFamily:'poppins'}} className='gp_text'>Gallery</Typography>      
      </Box>

      <Box sx={{width:'100%',display:'flex',flexDirection:"column",justifyContent:'center',alignItems:'center',margin:'50px 0px'}}>
      <ImageList
      sx={{width:'90%'}}
      variant="quilted"
      cols={4}
      rowHeight={"auto"}
    >
      {itemData.map((item) => (
        
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
            <img
              {...srcset(item.img, 121, )}
              alt={item.title}
              loading="lazy" 
            />
        </ImageListItem>
      ))}
    </ImageList>
      <ImageList
      sx={{width:'90%',mt:"0.5%"}}
      variant="quilted"
      cols={4}
      rowHeight={"auto"}
    >
      {vidData.map((item) => (
        
        <ImageListItem key={item.src} cols={item.cols || 1} rows={item.rows || 1}>
            <video controls muted>
              <source  src={item.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
        </ImageListItem>
      ))}
    </ImageList>
    {/* <video controls autoplay>
            <source  src={gall_vid1} type="video/mp4" />
            Your browser does not support the video tag.
    </video> */}
      </Box>
    </Layout>
  )
}

export default Gallery
