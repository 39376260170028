import React, { useState } from "react";
import { Box, Grid, Typography } from '@mui/material';
// import {Trust_data} from "../../Data/Trust_data";
import AddIcon from '@mui/icons-material/Add';
import "../../Styles/ClientTrustStyles.css"
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const ClientTrust = () => {


  
  const [counterState,setCounterState] = useState(false);

  return (
    <>
       <Box sx={{width:'100%',minHeight:'30vh',padding:{sm:'30px',xs:'20px'}}}>
        
        <Typography variant='h4' sx={{fontWeight:'700',fontFamily:'poppins',fontSize:{lg:'50px',md:'40px',sm:'27px',xs:'22px'}}} component={'div'}>Trust is Very <Typography variant='h4'  component={'span'} sx={{fontWeight:'700',fontFamily:'poppins',color:'#0abde3',fontSize:{lg:'50px',md:'40px',sm:'27px',xs:'22px'}}}>Important</Typography></Typography>

        <Typography sx={{margin:'10px 0px',fontSize:{lg:'20px',md:'15px',sm:'10px',xs:'8px',color:'#0B0B0B66'}}}>This highlights our skills, experience, and quality of work, demonstrating our commitment to excellence.</Typography>

      <ScrollTrigger onEnter={()=>setCounterState(true)} onExit={()=>setCounterState(false)}>
        <Grid container sx={{display:'flex',alignItems:'center',justifyContent:'center',margin:'20px 0px'}}>
          
          <Grid item md={2.7} sm={2.6} xs={12} sx={{height:{lg:'200px',md:'160px',sm:'140px',xs:'150px'}}}
           className='trust_box'>
            <Typography sx={{fontFamily:'poppins',fontWeight:'600',fontSize:{lg:'40px',md:'30px',sm:'20px',xs:'20px'}}}>
              {
                counterState &&
                <CountUp start={0} end={7}>
                </CountUp>
              }
              <AddIcon sx={{fontSize:{lg:'1.5rem',md:'1.3rem',sm:'1rem',xs:'0.9rem'}}}/></Typography>
            <Typography sx={{fontFamily:'poppins',fontWeight:'400',fontSize:{lg:'1rem',md:'.8rem',sm:'.5rem',xs:'.7rem'}}}>Years of Experience</Typography>
          </Grid>

          <Grid item md={2.7} sm={2.6} xs={12} sx={{height:{lg:'200px',md:'160px',sm:'140px',xs:'150px'}}}
           className='trust_box'>
            <Typography sx={{fontFamily:'poppins',fontWeight:'600',fontSize:{lg:'40px',md:'30px',sm:'20px',xs:'20px'}}}>
              {
                counterState &&
                <CountUp start={100} end={870}>
                </CountUp>
              }
            <AddIcon sx={{fontSize:{lg:'1.5rem',md:'1.3rem',sm:'1rem',xs:'0.9rem'}}}/></Typography>
            <Typography sx={{fontFamily:'poppins',fontWeight:'400',fontSize:{lg:'1rem',md:'.8rem',sm:'.5rem',xs:'.7rem'}}}>Happy Clients</Typography>
          </Grid>

          <Grid item md={2.7} sm={2.6} xs={12} sx={{height:{lg:'200px',md:'160px',sm:'140px',xs:'150px'}}}
           className='trust_box'>
            <Typography sx={{fontFamily:'poppins',fontWeight:'600',fontSize:{lg:'40px',md:'30px',sm:'20px',xs:'20px'}}}>
            {
                counterState &&
                <CountUp start={0} end={200}>
                </CountUp>
              }
              <AddIcon sx={{fontSize:{lg:'1.5rem',md:'1.3rem',sm:'1rem',xs:'0.9rem'}}}/></Typography>
            <Typography sx={{fontFamily:'poppins',fontWeight:'400',fontSize:{lg:'1rem',md:'.8rem',sm:'.5rem',xs:'.7rem'}}}>Employees</Typography>
          </Grid>

          <Grid item md={2.7} sm={2.6} xs={12} sx={{height:{lg:'200px',md:'160px',sm:'140px',xs:'150px'}}}
           className='trust_box'>
            <Typography sx={{fontFamily:'poppins',fontWeight:'600',fontSize:{lg:'40px',md:'30px',sm:'20px',xs:'20px'}}}>
            {
                counterState &&
                <CountUp start={0} end={17}>
                </CountUp>
              }
              <AddIcon sx={{fontSize:{lg:'1.5rem',md:'1.3rem',sm:'1rem',xs:'0.9rem'}}}/></Typography>
            <Typography sx={{fontFamily:'poppins',fontWeight:'400',fontSize:{lg:'1rem',md:'.8rem',sm:'.5rem',xs:'.7rem'}}}>Brands Across Countries</Typography>
          </Grid>
      
        </Grid>
      </ScrollTrigger>
        
    </Box>
    </>
  )
}

export default ClientTrust;
