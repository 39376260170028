import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import inc from "../../Assets/innercircle.png";
import outc from "../../Assets/outercircle.png";
import instar from "../../Assets/instar.png";
import outstar from "../../Assets/outstar.png";
import "../../Styles/OurServicesStyles.css";



function OurServices() {
  
  const [textIndex, setTextIndex] = useState(0);
  const texts = ["Software Solution", "Bulk WhatsApp", "Brand Promotion", "Digital Marketing"];
 
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000);
     
    return () => clearInterval(intervalId);
  }, [texts.length]);
 
  useEffect(() => {
    const centerX = 240; //240 Center x-coordinate
    const centerY = 240; //240 Center y-coordinate
    const stars = document.querySelectorAll('.star');
 
    const rotateStars = () => {
      const starPositions = Array.from({ length: 7 }).map((_, index) => {
        const angle = ((index * 360 / 7) + (textIndex * 360 / texts.length)) * Math.PI / 180;
        const radius = 100;
        const top = centerY - radius * Math.cos(angle);
        const left = centerX + radius * Math.sin(angle);
        return { top: `${top}px`, left: `${left}px` };
      });
 
      stars.forEach((star, index) => {
        star.style.transition = 'top 1s, left 1s';
        star.style.top = starPositions[index].top;
        star.style.left = starPositions[index].left;
      });
    };
 
    // Rotate stars initially and whenever text index changes
    rotateStars();
 
    const rotationInterval = setInterval(() => {
      rotateStars();
    }, 2000);
 
    return () => clearInterval(rotationInterval);
  }, [textIndex, texts.length]);
 
  useEffect(() => {
    const stars = document.querySelectorAll('.star');
    const centerX = 120; //120 Center x-coordinate
    const centerY = 120; //120 Center y-coordinate
 
    const moveToCenter = () => {
      stars.forEach(star => {
        star.style.transition = 'top 1s, left 1s';
        star.style.top = `${centerY}px`;
        star.style.left = `${centerX}px`;
      });
    };
 
    // Move stars to the center initially
    moveToCenter();
 
    // After 2 seconds, move stars back to their original positions
    setTimeout(() => {
      const rotateStars = () => {
        const starPositions = Array.from({ length: 7 }).map((_, index) => {
          const angle = ((index * 360 / 7) + (textIndex * 360 / texts.length)) * Math.PI / 180;
          const radius = 134;
          const top = centerY - radius * Math.cos(angle);
          const left = centerX + radius * Math.sin(angle);
          return { top: `${top}px`, left: `${left}px` };
        });
 
        stars.forEach((star, index) => {
          star.style.transition = 'top 1s, left 1s';
          star.style.top = starPositions[index].top;
          star.style.left = starPositions[index].left;
        });
      };
 
      // Rotate stars after moving to the center
      rotateStars();
    }, 200);
 
  }, [textIndex, texts.length]);
 
  return (
    <>
      <Grid container justifyContent="center">
        <Grid item sm={6} xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',paddingLeft:{lg:'35px',md:'30px',sm:'20px'}}}>

            <Typography variant='h4' sx={{fontWeight:'700',fontFamily:'poppins',margin:"20px 0px",fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'22px'}}} component={'div'}>Our<Typography variant='h4' component={'span'} sx={{fontWeight:'700',fontFamily:"poppins",color:'#0abde3',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'22px'}}}> Services</Typography></Typography>

          <Box sx={{'& > p':{fontWeight:'400',fontFamily:'poppins',fontSize:{lg:'25px',md:'17px',sm:'16px',xs:'11.5px'}}}}>
            <Typography sx={{padding:'0px 10px'}}>
            Explore cutting-edge software solutions tailored to elevate your business at every level. Our services encompass innovative applications, robust system encompass innovative applications, robust system integration, and strategic technology consulting to empower your digital success.
            </Typography>
         </Box>
         </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:'10%', marginBottom:'10%' }}>
          <div style={{ position: 'relative', width: '100%', maxWidth: '300px', height: '300px' }}>
            <img src={outc} alt="outer-circle" style={{ position: 'absolute', top: '10px', left: '0',width:'330px' }} />
            <img src={inc} alt="inner-circle" style={{ position: 'absolute', top: '35px', left: '25px',width:'250px' }} />
            <img src={instar} alt="instar" className="star" style={{ position: 'absolute', top: '100px', left: '100px', zIndex: 1 }} />
            {[...Array(6)].map((_, index) => (
              <img key={index} src={outstar} alt="outstar" className="star" style={{ position: 'absolute', top: '0', left: '0', zIndex: 1 }} />
            ))}
            <Typography variant="body1" style={{ fontSize: '16px',fontWeight: '700',fontSize:'24px' ,color: '#333', whiteSpace: 'nowrap', position: 'absolute', top: '55%', left: '50%', transform: 'translate(-50%, -50%)'}}>
              {texts[textIndex]}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
 

export default OurServices;