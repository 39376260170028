import React from 'react'
import Layout from '../Components/Layout/Layout';
import { Box, Typography ,Grid} from '@mui/material';
import {bulkWhatsappOffer} from "../Data/Bulk_WhatsappData";
import {bulkWhatsappSpecialOffer} from "../Data/Bulk_WhatsappData";
import bulk_whatsapp_banner from "../Assets/bulk_whatsapp_banner.png";
import { Link } from 'react-router-dom';
import BuildTogether from '../Components/Layout/BuildTogether';

const Bulk_Whatsapp = () => {

  return (
    <Layout>
      <Box sx={{width:'100%',minHeight:'50vh',background:`url(${bulk_whatsapp_banner}) no-repeat`,backgroundPosition:'center',backgroundSize:'100% 100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
        <Typography variant='h3' sx={{fontWeight:'700',fontFamily:'poppins',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'20px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'30px'},fontFamily:'poppins'}} className='gp_text'>Bulk WhatsApp</Typography>

        <Typography sx={{fontWeight:'500',fontFamily:'poppins',fontSize:{lg:'25px',md:'20px',sm:'14px',xs:'8px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'8px'},color:'#fff',fontFamily:'poppins'}}>Connect Seamlessly, Communicate Effectively with HyperNxt Messaging</Typography>
      </Box>

      <Box  sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
      <Box sx={{'& > .sms_para':{margin:{lg:'16px 0px',md:'14px 0px',sm:'12px 0px',xs:'9px 0px'}},'& > .sms_para > p':{fontWeight:'400',fontFamily:'poppins',fontSize:{xl:'25px',lg:'22px',md:'16px',sm:'12px',xs:'9px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'15px'},fontFamily:'poppins'},width:'90%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
      <Box className='sms_para'>
          <Typography>
          Are you ready to elevate your promotional game? Look no further than HyperNxt, your ultimate partner in updates, or critical alerts, our platform ensures that your messages reach the right audience at the right time. Benefit from high-speed delivery and comprehensive analytics to refine your strategy.           
          </Typography>
  
      </Box>
      <Box className='sms_para'>
          <Typography>
          Forge stronger connections using the world's favourite messaging platform – WhatsApp. With HyperNxt, you can send messages using your own number, adding a personal touch to your communication. Build trust and  credibility by connecting with your audience on a platform they use daily.      
          </Typography>
        
      </Box>
      <Box className='sms_para'>
        <Typography>
        Experience the synergy of Bulk SMS and WhatsApp Messaging with HyperNxt. Connect, engage, and drive results with our powerful messaging solutions. Whether you're a business reaching out to customers or an organization keeping your community informed, we've got you covered.
        </Typography>
        
      </Box>
      <Box  sx={{width:'100%',display:'flex',alignItems:'flex-start',justifyContent:'flex-start',textAlign:'start'}}>
        <Typography sx={{fontWeight:'700',fontFamily:'poppins',fontSize:{lg:'30px',md:'20px',sm:'16px',xs:'9px'},lineHeight:{lg:'45px',md:'30px',sm:'20px',xs:'20px'}}}>
         HyperNxt Messaging - Where Every Message Matters.
        </Typography>
      </Box>
      </Box>
      </Box>

    {/* ----------bulk offer------- */}
        <Box sx={{width:'100%',}}>
        <Typography sx={{textAlign:'center',fontWeight:'700',fontSize:{lg:'45px',md:'35px',sm:'25px',xs:'18px'},lineHeight:{lg:'67.5px',md:'67.5px',sm:'50px',xs:'30px'},color:'#0B0B0B'}}>Bulk Whatsapp</Typography>
        </Box>
      <Grid container sx={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:{lg:'0px 20px',md:'0px 20px',sm:'0px 15px'}}}>
          
            {
              bulkWhatsappOffer?.map((item,i)=>(
                <Grid item key={i} sm={2.7} xs={12} sx={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',margin:'20px 0px','& > img':{height:{xl:'385px',lg:'320px',md:'265px',sm:'200px',xs:'160px'},width:{xl:'325px',lg:'260px',md:'200px',sm:'150px',xs:'160px'}}}}>
                  <img src={item.Image} alt="item.Image" loading="lazy"/>
                  <button className='buyNow_btn'><Link>Buy Now</Link></button>
                </Grid>
              ))
            }
          
      </Grid>
      {/* -------bulk special offer----- */}
      <Box sx={{width:'100%',margin:{sm:'20px 0px'}}}>
        <Typography sx={{textAlign:'center',fontWeight:'700',fontSize:{lg:'45px',md:'35px',sm:'25px',xs:'18px'},lineHeight:'67.5px',color:'#0B0B0B'}}>Single Number Whatsapp</Typography>
        </Box>
      <Grid container sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          
            {
              bulkWhatsappSpecialOffer.map((item,i)=>(
                <Grid item key={i} xs={12} sm={2.7} sx={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',margin:'20px 0px','& > img':{height:{xl:'385px',lg:'320px',md:'265px',sm:'200px',xs:'160px'},width:{xl:'325px',lg:'260px',md:'200px',sm:'150px',xs:'160px'}}}}>
                  <img src={item.Image} alt="item.Image" loading="lazy"/>
                  <button className='buyNow_btn'><Link>Buy Now</Link></button>
                </Grid>
              ))
            }
          
      </Grid>
     
      <BuildTogether/>
    </Layout>
    
  )
}

export default Bulk_Whatsapp;
