import React from "react";
import { useEffect } from "react";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { FaInstagram, FaSquareFacebook, FaXTwitter } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";
import { MdLanguage } from "react-icons/md";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaLinkedin, FaWhatsapp } from "react-icons/fa";
import instagram_icon from "../../Assets/instagram_icon.png";
import facebook_icon from "../../Assets/facebook_icon.png";
import youtube_icon from "../../Assets/youtube_icon.png";
import whatsapp_icon from "../../Assets/whatsapp_icon.png";
import linkdin_icon from "../../Assets/linkdin_icon.png";
import x_icon from "../../Assets/x_icon.png";
import "../../Styles/Footer.css";
import footer_address_icon from "../../Assets/footer_address_icon.png";
import footer_phone_icon from "../../Assets/footer_phone_icon.png";
import newfotlogo from "../../Assets/new main logo.png";
import { X } from "@mui/icons-material";
import { Link } from "react-router-dom";

const Footer = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Box>
        <Grid
          container
          sx={{
            borderTop: "2px solid #0B0B0B4D",
            padding: {
              lg: "30px 10px",
              md: "30px 6px",
              sm: "30px 6px",
              xs: "15px 0px",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { sm: "row", xs: "column" },
            alignItems: { sm: "flex-start", xs: "center" },
            paddingLeft: { sm: "0px", xs: "0px" },
          }}
        >
          <Grid
            item
            sm={3.5}
            xs={12}
            sx={{
              "& > p": {
                fontFamily: "poppins",
                fontWeight: "300",
                fontSize: {
                  xl: "18px",
                  lg: "16px",
                  md: "14px",
                  sm: "10px",
                  xs: "1rem",
                },
                lineHeight: { xl: "27px", lg: "25px", md: "", sm: "", xs: "" },
                fontWeight: "300",
              },
              "& > img": {
                Width: {
                  xl: "230px",
                  lg: "300px",
                  md: "170px",
                  sm: "150px",
                  xs: "150px",
                },
                height: {
                  xl: "36px",
                  lg: "80px",
                  md: "95px",
                  sm: "70px",
                  xs: "10vh",
                },
                margin: { lg: "1rem", md: "0rem", xs: "3rem" },
                margin: { xl: "0px", lg: "0px", sm: "0px", xs: "auto" },
                display: { xs: "block" },
              },
            }}
          >
            <img
              src={newfotlogo}
              alt="logo_icon"
              sx={{ alignItems: { xs: "center" }, textAlign: { xs: "center" } }}
            />
            <Typography>We are a Conversational Engagement</Typography>
            <Typography>Platform empowering businesses to</Typography>
            <Typography>engage meaningfully with customers</Typography>
            <Typography>across commerce, marketing and </Typography>
            <Typography>support use-cases on 30+ channels</Typography>

            <Box
              sx={{
                "& > div > input": {
                  margin: { md: "8px", sm: "2px", xs: "5px" },
                  padding: { md: "5px 8px", sm: "0px 4px", xs: "3px 4px" },
                  outline: "none",
                  width: {
                    xl: "250px",
                    lg: "220px",
                    md: "170px",
                    sm: "115px",
                    xs: "80%",
                  },
                  height: {
                    xl: "40px",
                    lg: "30px",
                    md: "25px",
                    sm: "20px",
                    xs: "32px",
                  },
                  border: "1px solid #0B0B0B",borderRadius:'50px'
                },

                "& > div > button": {
                  width: {
                    xl: "100px",
                    lg: "80px",
                    md: "70px",
                    sm: "50px",
                    xs: "80px",
                  },
                  height: {
                    xl: "40px",
                    lg: "30px",
                    md: "25px",
                    sm: "20px",
                    xs: "32px",
                  },
                  border: "none",
                  cursor: "pointer",
                  outline: "none",
                  background: "#38B6FF",
                  color: "#fff",
                  borderRadius:'50px',
                  fontSize: {
                    xl: "14px",
                    lg: "14px",
                    md: "12px",
                    sm: "9px",
                    xs: "10px",
                  },
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  fontFamily: "poppins",
                  fontSize: {
                    xl: "25px",
                    lg: "20px",
                    md: "18px",
                    sm: "14px",
                    xs: "1.3rem",
                  },
                  lineHeight: {
                    xl: "37.5px",
                    lg: "33px",
                    md: "30px",
                    sm: "25px",
                    xs: "25px",
                  },
                  marginTop: "15px",
                  textAlign: {
                    lg: "left",
                    md: "left",
                    sm: "left",
                    xs: "center",
                  },
                }}
              >
                News Letter
              </Typography>

              <Box
                component={"div"}
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <input type="email" placeholder="Your email address" />
                <button>Subscribe</button>
              </Box>
            </Box>
          </Grid>
          <Grid
            display={"flex"}
            justifyContent={"space-around"}
            sx={{
              width: { sm: "35%", xs: "80%" },
              paddingTop: { sm: "0px", xs: "12px" },
              paddingRight: { sm: "15px", xs: "0px" },
            }}
          >
            <Grid item width={"auto"}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontFamily: "poppins",
                  fontSize: {
                    xl: "30px",
                    lg: "25px",
                    md: "22px",
                    sm: "14px",
                    sx: "12px",
                  },
                  textDecoration: "underline",
                }}
              >
                Quick Links
              </Typography>
              <Box
                sx={{
                  "& > p": {
                    fontFamily: "poppins",
                    fontWeight: "400",
                    fontSize: {
                      xl: "25px",
                      lg: "20px",
                      md: "17px",
                      sm: "11px",
                      sx: "10px",
                    },
                    lineHeight: {
                      xl: "50px",
                      lg: "40px",
                      md: "30px",
                      sm: "22px",
                      sx: "",
                    },
                    fontWeight: "400",
                  },
                  "& > p >a": { textDecoration: "none", color: "#0B0B0B" },
                }}
              >
                <Typography>
                  <Link to={"/about"}>About Us</Link>
                </Typography>
                <Typography>
                  <Link to={"/carrer_opertunities"}>Careers</Link>
                </Typography>
                <Typography>
                  <Link to={"/products"}>Products</Link>
                </Typography>
                <Typography>
                  {/* <Link to={"/gallery"}>Gallery</Link> */}
                </Typography>
                <Typography>
                  <Link to={"/newsroom"}>Newsroom</Link>
                </Typography>
              </Box>
            </Grid>

            <Grid item width={"auto"}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontFamily: "poppins",
                  fontSize: {
                    xl: "30px",
                    lg: "25px",
                    md: "22px",
                    sm: "14px",
                    sx: "12px",
                  },
                  textDecoration: "underline",
                }}
              >
                Services
              </Typography>

              <Box
                sx={{
                  "& > p": {
                    fontFamily: "poppins",
                    fontWeight: "400",
                    fontSize: {
                      xl: "25px",
                      lg: "20px",
                      md: "17px",
                      sm: "11px",
                      sx: "10px",
                    },
                    lineHeight: {
                      xl: "50px",
                      lg: "40px",
                      md: "30px",
                      sm: "22px",
                      sx: "",
                    },
                    fontWeight: "400",
                  },
                  "&> p >a": { textDecoration: "none", color: "#0B0B0B" },
                }}
              >
                <Typography>
                  <Link to={"/software_solutions"}>Software Solutions</Link>
                </Typography>
                <Typography>
                  <Link to={"/brand_promotions"}>Brand Promotion</Link>
                </Typography>
                <Typography>
                  <Link to={"/digital_marketing"}>Digital Marketing</Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            sm={3.6}
            xs={12}
            sx={{ paddingTop: { sm: "0px", xs: "12px" } }}
          >
            <Typography
              sx={{
                display: { sm: "inline-block", xs: "block" },
                fontWeight: "700",
                fontFamily: "poppins",
                fontSize: {
                  xl: "30px",
                  lg: "25px",
                  md: "22px",
                  sm: "14px",
                  sx: "12px",
                },
                textDecoration: "underline",
                textAlign: { lg: "left", md: "left", sm: "left", xs: "center" },
              }}
            >
              Get In Touch
            </Typography>

            <Typography
              sx={{
                display: "flex",
                fontFamily: "poppins",
                fontWeight: "400",
                fontSize: {
                  xl: "22px",
                  lg: "18px",
                  md: "15px",
                  sm: "15px",
                  sx: "",
                },
                lineHeight: {
                  xl: "33px",
                  lg: "28px",
                  md: "21px",
                  sm: "",
                  sx: "",
                },
                fontWeight: "500",
                fontFamily: "poppins",
                margin: "10px 0px",
                "& > img": {
                  width: {
                    xl: "25px",
                    lg: "22px",
                    md: "20px",
                    sm: "14px",
                    xs: "14px",
                  },
                  height: {
                    xl: "25px",
                    lg: "22px",
                    md: "16px",
                    sm: "14px",
                    xs: "14px",
                  },
                  marginRight: "10px",
                },
              }}
            >
              <img src={footer_phone_icon} alt="" />
              +91 82498 46606
            </Typography>
            <Typography
              sx={{
                display: "flex",
                "& > img": {
                  width: {
                    xl: "25px",
                    lg: "22px",
                    md: "20px",
                    sm: "14px",
                    xs: "14px",
                  },
                  height: {
                    xl: "25px",
                    lg: "22px",
                    md: "20px",
                    sm: "14px",
                    xs: "14px",
                  },
                  marginRight: "10px",
                },
              }}
            >
              <img src={footer_address_icon} alt="" />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  "& > p": {
                    fontSize: {
                      xl: "19px",
                      lg: "16px",
                      md: "12px",
                      sm: "9px",
                      xs: "9px",
                    },
                    fontWeight: "500",
                    fontFamily: "poppins",
                    lineHeight: {
                      xl: "28.5px",
                      lg: "25px",
                      md: "20px",
                      sm: "14px",
                      xs: "12px",
                    },
                  },
                }}
              >   
                <Typography>HyperNxt Technology PVT LTD </Typography>
                <Typography>17, Outer Ring Rd,</Typography>
                <Typography>Near Reliance Fresh, KEB Colony,</Typography>
                <Typography>New Gurappana Palya, 1st Stage, BTM Layout, Bengaluru, Karnataka </Typography>
                <Typography>560029.</Typography>
              </Box>
            </Typography>
            <div className="social-links">
              <a href="">
                <FaInstagram />
              </a>
              <a href="">
                <FaSquareFacebook />
              </a>
              <a href="">
                <IoLogoYoutube />
              </a>
              <a href="https://www.linkedin.com/company/hypersage/mycompany/">
                <FaLinkedin />
              </a>
              <a href="">
                <FaXTwitter />
              </a>
              <a href="">
                <FaWhatsapp />
              </a>
            </div>
            {/* <Box
              sx={{
                paddingTop: "12px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                "& > .MuiIconButton-root": {
                  padding: {
                    xl: "8px",
                    lg: "6px",
                    md: "5px",
                    sm: "4px",
                    xs: "4px",
                  },
                  margin: "0px 5px",
                },
                "& > .MuiIconButton-root > img": {
                  width: {
                    xl: "28px",
                    lg: "24px",
                    md: "20px",
                    sm: "12px",
                    xs: "12px",
                  },
                  height: {
                    xl: "28px",
                    lg: "24px",
                    md: "20px",
                    sm: "12px",
                    xs: "12px",
                  },
                },
              }}
            >
              <IconButton>
                <a
                  target="_blank"
                  href="https://www.instagram.com/HyperNxt_tech/"
                >
                  <img
                    style={{ height: 30, width: 30 }}
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/9/95/Instagram_logo_2022.svg"
                    }
                    alt="instagram_icon"
                  />
                </a>
              </IconButton>

              <IconButton>
                <a
                  target="_blank"
                  href="https://www.facebook.com/profile.php?id=61607470334"
                >
                  <img
                    style={{ height: 30, width: 30 }}
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/2023_Facebook_icon.svg/1920px-2023_Facebook_icon.svg.png"
                    }
                    alt="facebook_icon"
                  />
                </a>
              </IconButton>

              <IconButton>
                <img
                  style={{ height: 40, width: 40 }}
                  src={
                    "https://upload.wikimedia.org/wikipedia/commons/7/72/YouTube_social_white_square_%282017%29.svg"
                  }
                  alt="youtube_icon"
                />
              </IconButton>

              <IconButton>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/HyperNxt/mycompany/"
                >
                  <img
                    style={{ height: 30, width: 30 }}
                    src={
                      "https://t0.gstatic.com/images?q=tbn:ANd9GcRMCA3j2A8hfLl9p5UAU5nd9lvqLlNZvqoU4xOsZ192uH4IYS6X"
                    }
                    alt="linkdin_icon"
                  />
                </a>
              </IconButton>

              <IconButton>
                <img
                  style={{ height: 30, width: 30 }}
                  src={
                    "https://pbs.twimg.com/profile_images/1683899100922511378/5lY42eHs_400x400.jpg"
                  }
                  alt="x_icon"
                />
              </IconButton>

              <IconButton>
                <a target="_blank" href="https://wa.me/8249846606">
                  <img
                    style={{ height: 40, width: 40 }}
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                    }
                    alt="whatsapp_icon"
                  />
                </a>
              </IconButton>
            </Box> */}
          </Grid>
        </Grid>

        {/*------------lower footer------------  */}

        <Box
          component="section"
          sx={{
            width: "100%",
            height: { sm: "50px", xs: "100px" },
            textAlign: "center",
            bgcolor: "#F1F1F1",
            color: "#2c3e50",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderTop: "1px solid #0B0B0B4D",
            padding: "10px 40px",
          }}
        >
          <Grid
            container
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid
              item
              sm={6}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { sm: "flex-start", xs: "center" },
              }}
            >
              <Typography
                component={"div"}
                sx={{
                  fontWeight: "400",
                  fontFamily: "poppins",
                  fontSize: { md: "0.9", sm: "0.8rem", xs: "0.8rem" },
                }}
              >
                HyperNxt &copy; 2024. All rights reserved.
              </Typography>
            </Grid>

            <Grid
              item
              sm={6}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { sm: "flex-end", xs: "center" },
                alignItems: "center",
                "& > .MuiBox-root > .MuiTypography-root": {
                  md: "1rem",
                  sm: ".8rem",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  "& > p > a": { textDecoration: "none", color: "#0B0B0B" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontFamily: "poppins",
                    margin: "0px 10px",
                    fontSize: { md: "0.9", sm: "0.8rem", xs: "0.8rem" },
                  }}
                >
                  <Link to={"/terms_services"}>Terms Of Services</Link>
                </Typography>
                <Divider
                  orientation="vertical"
                  sx={{ borderRightWidth: "3px" }}
                  flexItem
                />
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontFamily: "poppins",
                    margin: "0px 10px",
                    fontSize: { md: "0.9", sm: "0.8rem", xs: "0.8rem" },
                  }}
                >
                  <Link to={"/privacy_policy"}>Privacy Policy</Link>
                </Typography>
                <Divider
                  orientation="vertical"
                  sx={{ borderRightWidth: "3px" }}
                  flexItem
                />
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontFamily: "poppins",
                    margin: "0px 10px",
                    fontSize: { md: "0.9", sm: "0.8rem", xs: "0.8rem" },
                  }}
                >
                  <Link>Cookie Policy</Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
